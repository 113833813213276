import { ActionItem, ActionTable, AntTable, SVGIcon } from '@components';
import { ParamsSettings } from '@types';
import { getPaginationFromAntdTable, getSorterParamsFromAntdTable } from '@utils/helper';
import { Popconfirm, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppsNewestDocument } from 'src/types/admin-keyword';
import '../styles.scss';
import moment from 'moment';

interface Props {
  dataSource?: any[];
  isLoading?: boolean;
  pagination?: {
    totalRows?: number;
  };
  sortOrder?: 'ascend' | 'descend';
  currentPage: number;
  onFilter?: (params: ParamsSettings<any>) => void;
  onRefresh?: () => void;
  onAdd: (_id: string) => void;
  onRemove: (_id: string) => void;
  onChangeStatus: (e: boolean, app_id: string, currentPage: number) => void;
  onCurrentPage: (e: number) => void;
}

const { Text } = Typography;

export const TableAppNewest = ({
  dataSource = [],
  isLoading,
  pagination,
  currentPage,
  onFilter,
  onAdd,
  onCurrentPage,
  onRemove,
}: Props) => {
  const navigate = useHistory();
  const [open, setOpen] = useState<boolean>(false);
  const [rowSelected, setRowSelected] = useState<any>();
  const [pageSize, setPageSize] = useState<number>(10);
  const [openDelete, setOpenDelete] = useState(false);

  const handleClickOpen = (row: AppsNewestDocument) => () => {
    setRowSelected(row);
    setOpen(true);
  };

  const columns: ColumnsType<AppsNewestDocument> = [
    {
      key: 'app_icon',
      dataIndex: 'app_icon',
      title: 'App Icon',
      render: (value: string, item) => (
        <img src={item?.detail?.app_icon ?? ''} width={40} height={40} style={{ borderRadius: 9999 }} />
      ),
      width: 200,
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Name',
      render: (value: string, item) => <Text>{item?.detail?.name ?? ''}</Text>,
      width: 200,
    },
    {
      key: 'app_id',
      dataIndex: 'app_id',
      title: 'App ID',
      render: (value: string, item) => <Text onClick={handleClickOpen(item)}>{value}</Text>,
      width: 200,
    },
    {
      key: 'lauched',
      dataIndex: 'lauched',
      title: 'Launched',
      render: (value: string, item) => <Text>{moment(item?.detail?.launched ?? '').format('YYYY-MM-DD')}</Text>,
      width: 200,
    },
    {
      key: 'action',
      title: 'Action',
      render: (_, row) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Popconfirm
              title="Are you sure？"
              okText="Yes"
              cancelText="No"
              onConfirm={() => (row.userSelected ? onRemove(row.app_id) : onAdd(row.app_id))}
            >
              <div className="add-app-button">
                {row.userSelected ? (
                  <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    <SVGIcon name="table_delete" width={18} height={18} />
                    <span style={{ marginLeft: 5, textDecoration: 'underline' }}>Remove</span>
                  </div>
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    <SVGIcon name="table_add" width={18} height={18} />
                    <span style={{ marginLeft: 5, textDecoration: 'underline' }}>Add</span>
                  </div>
                )}
              </div>
            </Popconfirm>
          </div>
        );
      },
      align: 'center',
      width: 150,
    },
  ];

  return (
    <div style={{ margin: '24px 0' }}>
      <AntTable
        columns={columns}
        dataSource={dataSource}
        rowKey={(r) => r._id}
        loading={isLoading}
        pagination={{
          pageSize: pageSize,
          total: pagination?.totalRows,
          current: currentPage + 1,
          pageSizeOptions: [10],
        }}
        onChange={(pagination, _, sorter, extra) => {
          onCurrentPage(getPaginationFromAntdTable({ pagination, extra }));
          onFilter?.({
            page: getPaginationFromAntdTable({ pagination, extra }),
            sorter: getSorterParamsFromAntdTable({ sorter }),
            searcher: {},
            pageSize: pagination.pageSize,
          });
          setPageSize(pagination.pageSize ?? pageSize);
        }}
      />
    </div>
  );
};
