import { SubscriptionEnum } from './admin-partner';
import { PlatformEnum } from './base';

export interface SubscriptionDocument {
  user: string;
  startDate: string;
}

export interface FeatureDocument {
  name: string;
  type: SubscriptionEnum;
  value: string;
}

export interface PlanDocument {
  planId: string;
  name: string;
  months: number;
  plusMonths: number;
  price: number;
}

export interface ProductPlanDocument {
  _id: string;
  subscriptionType: SubscriptionEnum;
  name: string;
  active: boolean;
  currency: string;
  priority: number;
  monthlyPrice: number;
  subscriptionFeatures: FeatureDocument[];
  plans: PlanDocument[];
  flatform: PlatformEnum;
}

export type UpdateSubscriptionParam = {
  subscriptionType: SubscriptionEnum;
  period: number;
};

export enum FeatureEnum {
  TRIAL_TIME = 'TRIAL_TIME',
  APPS = 'APPS',
  CONNECT_GOOGLE = 'CONNECT_GOOGLE',
  KEYWORD = 'KEYWORD',
  CONNECT_PARTNER = 'CONNECT_PARTNER',
  CATEGORY_POSITION = 'CATEGORY_POSITION',
  DETAIL_LOG = 'DETAIL_LOG',
  COMPARE_COMPETITOR = 'COMPARE_COMPETITOR',
}

export interface PartnerSubscriptionDocument {
  user: string;
  startDate: Date;
  endDate: Date;
  flatform: PlatformEnum;
  subscriptionType: SubscriptionEnum;
}

export interface PartnerCreateSubscription {
  subscriptionType: SubscriptionEnum;
  period: number;
  returnUrl: string;
  cancelUrl: PlatformEnum;
}
