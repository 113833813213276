import { Table } from 'antd';
import { TableProps } from 'antd/lib/table';
// import 'antd/lib/table/style/css';
import 'antd/lib/table/style';
import './styles.scss';

export const AntTable = <T extends object = any>({ ...props }: TableProps<T>) => {
  return (
    <div className="lets-table">
      <Table {...props} />
    </div>
  );
};
