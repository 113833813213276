export const ROUTERS = {
  NOT_FOUND: '/404',
  UNKNOWN_ERROR: '/500',
  LOGIN: '/login',
};

export const ROUTERS_MAIN = {
  HOME: '/',
  DASHBOARD: '/admin/dashboard',
  USER: '/admin/user-management',
  SUBCRIPTION: '/admin/sub-management',
  PARTNER_SUBCRIPTION: '/admin/partner-subscription',
  KEYWORD: '/admin/keyword',
  PARTNER_KEYWORD: '/admin/partner/:username/keyword',
  APP: '/admin/apps',
  PARTNER_APP: '/admin/partner/:username/apps',
  KEYWORD_BY_APP: '/admin/users-keywords/:app/apps',
  USER_CONNECT_GA_BY_APP: '/admin/users-connect-ga/:app/apps',
  USER_CONNECT_PARTNER_BY_APP: '/admin/users-connect-shopify/:app/apps',
  PARTNER_APP_KEYWORD: '/admin/partner/app/:appId/keyword',
  ADMIN_PARTNER_APP_KEYWORD: '/admin/partner/app/:username/:appId/keyword',
  PARTNER: '/admin/partners',
  PROMO: '/admin/promo',
  CONFIG_ENV: '/admin/config/env',
  SUB_TX: '/admin/transaction',
  SUB_PARTNER_TX: '/admin/partner/transaction/subscription',
  PARTNER_ADD_APPS: '/admin/partner/apps/news',
  CATEGORY: '/admin/categories',
  COLLECTION: '/admin/collections',
  BLOG: '/admin/blogs',
  LIST_APP_PARTNER_MEMBER: '/admin/partner-member/:username/list-apps',
};
