import { Link } from 'react-router-dom';

import { PNGIcon } from '../PNGIcon';

export const Logo: React.FC = () => {
  return (
    <Link to="/">
      <PNGIcon name="logo" width={'150px'} height={'100%'} />
    </Link>
  );
};
