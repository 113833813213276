import { SorterResult, TableCurrentDataSource, TablePaginationConfig } from 'antd/lib/table/interface';
import { PropsWithChildren } from 'react';
import { RouteComponentProps } from 'react-router-dom';

export type RouteItem = {
  path: string;
  component: React.LazyExoticComponent<React.FC<PageProps>> | React.FC<PropsWithChildren<PageProps>>;
  routes?: RouteItem[];
  guard?: boolean;
};

export type PageProps = RouteComponentProps & { routes?: RouteItem[] };

export type ErrorType = {
  code?: number;
  message?: string;
};

export type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;

export type Response<T> = {
  code: number;
  data: T;
};

export type Transaction = {
  type: 'created' | 'pending' | 'finish';
  error?: ErrorType;
};

export type PaginationValues = {
  totalPages: number;
  totalRows: number;
};

export type PaginationResponse<T> = {
  hits: T;
  pagination: PaginationValues;
};

export enum ThemeEnum {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
}

export enum LanguageEnum {
  EN = 'EN',
  VN = 'VN',
}

export interface GetPaginationFromAntdTable<T> {
  extra: TableCurrentDataSource<T>;
  pagination: TablePaginationConfig;
}

export interface GetSorterParamsFromAntdTable<T> {
  sorter: SorterResult<T> | Array<SorterResult<T>>;
}

export enum PlatformEnum {
  SHOPIFY = 'SHOPIFY',
  WIX = 'WIX',
}

export enum RecordStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
