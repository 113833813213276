import { ActionItem, ActionTable, AntTable, SVGIcon } from '@components';
import { ParamsSettings } from '@types';
import { getPaginationFromAntdTable, getSorterParamsFromAntdTable } from '@utils/helper';
import { Popconfirm, Tag, Col, Row } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AddKeywordParams, AdminPartnerKeywordDocument } from 'src/types/admin-keyword';
import { Typography } from 'antd';
import colors from '@utils/colors';

interface Props {
  dataSource?: any[];
  isLoading?: boolean;
  pagination?: {
    totalRows?: number;
  };
  sortOrder?: 'ascend' | 'descend';
  currentPage: number;
  onFilter?: (params: ParamsSettings<any>) => void;
  onRemove?: (e: AddKeywordParams, currentPage: number, hash_user?: string) => void;
  onRefresh?: () => void;
  onPageChange: (e: any) => void;
  onCurrentPage: (e: number) => void;
  isShowListKeyword?: boolean;
  isDisabled?: boolean;
}

const { Text } = Typography;


export const TableKeyword = ({ dataSource = [], isLoading, pagination, currentPage, onFilter, onRemove, onCurrentPage, isShowListKeyword, isDisabled}: Props) => {
  const navigate = useHistory();
  const [open, setOpen] = useState<boolean>(false);
  const [rowSelected, setRowSelected] = useState<AdminPartnerKeywordDocument>();
  const [pageSize, setPageSize] = useState<number>(10)


  const [openDelete, setOpenDelete] = useState(false);

  const handleClickOpen = (row: AdminPartnerKeywordDocument) => () => {
    setRowSelected(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenDelete(false);
  };

  const removeKeyDetails = (key: string) => {
    const keys = ['_id', 'createdAt', 'updatedAt', '__v', 'title', 'company', 'status'];
    return keys.includes(key);
  };

  const columns: ColumnsType<AdminPartnerKeywordDocument> = [
    {
      key: 'app_id',
      dataIndex: 'app_id',
      title: 'App Id',
      render: (value: string, item) => (
        <Text>
          <a className="app-link" href={`https://letsmetrix.com/app/${item.app_id}`} target="_blank">
            {value}
          </a>
        </Text>
      ),
      width: 200,
      hidden: isShowListKeyword
    },
    {
      key: 'created_at',
      dataIndex: 'created_at',
      title: 'Created At',
      render: (value: string, item) => <Text onClick={handleClickOpen(item)}>{value}</Text>,
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      width: 200,
      hidden: isShowListKeyword
    },
    {
      key: 'slug',
      dataIndex: 'slug',
      title: 'Slug Keyword',
      render: (value: string, item: AdminPartnerKeywordDocument) => {
        return <Text>{isShowListKeyword ? item?.slug ?? '' : item?.keyword?.slug ?? ''}</Text>
      },
      width: 250,
    },

    {
      key: 'text',
      dataIndex: 'text',
      title: 'Text Keyword',
      render: (value: string, item: AdminPartnerKeywordDocument) => <Text>{isShowListKeyword ? item?.text ?? '' : item?.keyword?.text ?? ''}</Text>,
      width: 200,
    },

    {
      key: 'action',
      title: 'Action',
      render: (_, row) => {
        const deleteActions: ActionItem<AdminPartnerKeywordDocument> = {
          name: 'table_delete',
          onClickAction: (row: AdminPartnerKeywordDocument) => { },
          width: 18,
          height: 18,
        };
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {onRemove ? <Popconfirm
              title='Are you sure？'
              okText='Yes'
              cancelText='No'
              onConfirm={() => onRemove({ app_id: row.app_id, keyword: row?.keyword?.text ?? '' }, currentPage, row?.hash_user)}
            >
              <div className='remove'>
                <ActionTable action={deleteActions} row={row} />
              </div>
            </Popconfirm> : <SVGIcon name='table_delete' style={{ opacity: isDisabled ? 0.5 : 1, pointerEvents: isDisabled ? 'none' : 'auto'}} />}
          </div>
        );
      },
      align: 'center',
      width: 150,
      hidden: isShowListKeyword
    },
    {
      key: 'users_add_keyword',
      dataIndex: 'users_add_keyword',
      title: 'Users add keyword',
      render: (value: string, item) => (
        <ul className='list-user'>
          {item.users_add_keyword && item.users_add_keyword.length !== 0 ? item.users_add_keyword.map((user, index) => 
            <li>
              <Row key={index}>
                <Col span={10} style={{fontSize:"14px"}}>{user.username ? user.username : 'Inactive User'}</Col>
                <Col span={10} style={{fontSize:"14px"}}>| {user.created_at}</Col>
              </Row>
            </li>
          ) : <>-</>}
        </ul>
      ),
      width: 500,
      hidden: !isShowListKeyword
    },
    {
      key: 'apps_add_keyword',
      dataIndex: 'apps_add_keyword',
      title: 'Apps add keyword',
      render: (value: string, item) => (
        <ul className='list-category'>
          {item.apps_add_keyword && item.apps_add_keyword.length !== 0 ? item.apps_add_keyword.map((app, index) => 
            <li key={index}>
              <Tag color={colors.secondary[110]}>{app.count}</Tag>
              <Text>{app._id ? app._id : '-'}</Text>
              <br></br>
              <Text>Last added at: {app.created_at}</Text>
            </li>
          ) : <>-</>}
        </ul>
      ),
      width: 300,
      hidden: !isShowListKeyword
    },
  ];

  return (
    <div style={{ margin: '24px 0' }}>
      <AntTable
        columns={columns}
        dataSource={dataSource}
        rowKey={(r) => r._id}
        loading={isLoading}
        pagination={{
          pageSize: pageSize,
          total: pagination?.totalRows,
          current: currentPage + 1,
          pageSizeOptions: [10]
        }}
        onChange={(pagination, _, sorter, extra) => {
          onCurrentPage(getPaginationFromAntdTable({ pagination, extra }));
          onFilter?.({
            page: getPaginationFromAntdTable({ pagination, extra }),
            sorter: getSorterParamsFromAntdTable({ sorter }),
            searcher: {},
            pageSize: pagination.pageSize
          });
          setPageSize(pagination.pageSize ?? pageSize)
        }}
      />
    </div>
  );
};
