import React from 'react';

import * as Svgs from '@assets/svg';

import { Props } from './types';

export const SVGIcon: React.FC<Props> = ({ name, width = '16', height = '16', ...rest }) => {
  // eslint-disable-next-line
  const Icon = Svgs[name];

  const style: { width?: number | string; height?: number | string } = {};
  if (width) style.width = width;
  if (height) style.height = height;

  return Icon ? <Icon {...style} {...rest} /> : null;
};
