export interface PaypalConfigDocument {
  _id: string;
  createdBy: string;
  clientId: string;
  baseUrl: string;
  clientSecret: string;
  merchantId: string;
  env: string;
  gateway: string;
  createdAt: string;
  updatedAt: string;
}

export enum EnvConfig {
  DEVELOPMENT = 'DEVELOPMENT',
  PRODUCTION = 'PRODUCTION',
}

export enum PaymentGatewayEnum {
  PAYPAL = 'PAYPAL',
}

export type PaypalConfigParams = {
  gateway: PaymentGatewayEnum;
  env: EnvConfig;
  clientId: string;
  baseUrl: string;
  clientSecret: string;
  merchantId: string;
};

export type ConfigEnvParams = {
  env: EnvConfig;
};

export type PaypalconfigEnvParams = {
  gateway: PaymentGatewayEnum;
  env: EnvConfig;
};
