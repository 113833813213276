import api from '@services/api';
import { PlatformEnum, ResponseDetailSuccess, UpdateProductPlanRequestParam } from '@types';
import { useRequest } from 'ahooks';
import {
  PartnerCreateSubscription,
  PartnerSubscriptionDocument,
  ProductPlanDocument,
  UpdateSubscriptionParam,
} from 'src/types/admin-subscription';

export const getListProductPlans = async (): Promise<ResponseDetailSuccess<ProductPlanDocument[]>> => {
  const response: ResponseDetailSuccess<ProductPlanDocument[]> = await api.getListDocument({
    url: '/admin/root/subscriptions/product',
    params: {},
  });
  return response;
};

export const useGetListProductPlans = () => {
  return useRequest<ResponseDetailSuccess<ProductPlanDocument[]>, []>(getListProductPlans, {
    manual: true,
  });
};

export const updatePriceProductPlan = async (
  data: UpdateProductPlanRequestParam & { platform: PlatformEnum },
  productId: string,
): Promise<ResponseDetailSuccess<ProductPlanDocument>> => {
  const response: ResponseDetailSuccess<any> = await api.putDocument({
    url: `/admin/root/subscriptions/${productId}/product`,
    data: data,
  });

  return response.data as ResponseDetailSuccess<ProductPlanDocument>;
};

export const updateSubscriptionForPartner = async (
  data: UpdateSubscriptionParam,
  username: string,
): Promise<ResponseDetailSuccess<ProductPlanDocument>> => {
  const response: ResponseDetailSuccess<any> = await api.putDocument({
    url: `/admin/root/subscriptions/${username}/update`,
    data: data,
  });

  return response.data as ResponseDetailSuccess<ProductPlanDocument>;
};

export const createSubscriptionForPartner = async (
  data: PartnerCreateSubscription,
): Promise<ResponseDetailSuccess<any>> => {
  const response: ResponseDetailSuccess<any> = await api.postDocument({
    url: `/admin/partner/subscriptions/order`,
    data: data,
  });

  return response as ResponseDetailSuccess<any>;
};

export const currentSubscription = async (): Promise<ResponseDetailSuccess<PartnerSubscriptionDocument>> => {
  const response: ResponseDetailSuccess<PartnerSubscriptionDocument> = await api.getListDocument({
    url: `/admin/partner/subscriptions/registed`,
    params: {},
  });

  return response as ResponseDetailSuccess<PartnerSubscriptionDocument>;
};

export const useGetCurrentSubscription = () => {
  return useRequest<ResponseDetailSuccess<PartnerSubscriptionDocument>, []>(currentSubscription, {
    manual: true,
  });
};
