import { useRootGetListTransaction } from '@services/manage-tx/manage-tx';
import { PageProps } from '@types';
import { useEffect, useState } from 'react';
import { FilterTx } from './components/FilterTx';
import { TableTx } from './components/TableTx';
import './styles.scss';
import { useGetListProductPlans } from '@services/manage-subscription/manage-subscription';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../hooks/useQuery';

export const ManagementTx: React.FC<PageProps> = () => {
  const { data: listTxs, loading, run, refresh } = useRootGetListTransaction();
  const { data: listProductPlans, run: getProductPlan } = useGetListProductPlans();
  const [searchValue, setSearchValue] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<'ascend' | 'descend' | undefined>();
  const [openAdd, setOpenAdd] = useState<boolean>(false);
  const [currentPg, setCurrentPg] = useState<number>(0)
  const history = useHistory()
  const query = useQuery()

  const onHandleGetDefaultData = () => {
    const loadPage = query?.get('page')
    let page = 0
    if (!loadPage) page = 1
    else page = +loadPage

    history.push('?page=' + page)
    setCurrentPg(page - 1)
    run({
      page: page - 1,
      searcher: {},
      sorter: {},
    });
  }

  useEffect(() => {
    onHandleGetDefaultData()
    getProductPlan()
  }, []);


  const onHandleChangePage = (e: number) => {
    setCurrentPg(e)
    const _e = e + 1
    history.push('?page=' + _e)
  }

  return (
    <div style={{ padding: '24px' }}>
      <h2>Transaction Management</h2>
      <FilterTx
        onOpen={() => setOpenAdd(true)}
        totalRecords={listTxs?.data?.pagination?.totalRows ?? 0}
        onSearch={(value: string) => {
          run({
            page: 0,
            searcher: {
              user: { value: value ?? undefined, operator: 'contains' },
            },
            sorter: {},
          });
          setSearchValue(value);
          setSortOrder(undefined);
          setCurrentPg(0)
        }}
      />
      <TableTx
        onPageChange={run}
        productPlan={listProductPlans?.data}
        onRemove={() => { }}
        dataSource={listTxs?.data?.hits ?? []}
        isLoading={loading}
        sortOrder={sortOrder}
        pagination={listTxs?.data.pagination}
        onCurrentPage={(e: number) => onHandleChangePage(e)}
        currentPage={currentPg}
        onFilter={({ page, sorter, pageSize }) => {
          setSortOrder(!!sorter.title ? (sorter.title === 'asc' ? 'ascend' : 'descend') : undefined);
          run({
            page,
            sorter,
            searcher: {
              user: { value: searchValue ?? undefined, operator: 'contains' },
            },
            pageSize
          });
        }}
      />
    </div>
  );
};
