import { EnvConfig, PageProps, PaymentGatewayEnum, PaypalConfigParams } from '@types';
import { useEffect, useState } from 'react';

import { setConfigEnv, setPaypalConfigEnv, useGetConfigEnv, useGetPaypalConfigByEnv } from '@services/manage-config/manage-config';
import { Button, Form, Input, Spin, Switch, message } from 'antd';
import './styles.scss';
import { Loading } from '@components';

export const ManagementConfig: React.FC<PageProps> = () => {
    const { data: config, loading, run, refresh } = useGetConfigEnv();
    const { data: configPaypal, loading: paypalConfigLoading, run: getPaypalConfig } = useGetPaypalConfigByEnv();
    const [form] = Form.useForm();
    const [env, setEnv] = useState<boolean>(false)
    const [isEnvLoading, setIsEnvLoading] = useState<boolean>(false)
    const [envConfigPaypal, setEnvConfigPaypal] = useState<boolean>(false)
    const [isConfigLoading, setIsConfigLoading] = useState<boolean>(false)

    useEffect(() => {
        run();

        const paypalEnv = envConfigPaypal ? EnvConfig.PRODUCTION : EnvConfig.DEVELOPMENT
        getPaypalConfig({ env: paypalEnv, gateway: PaymentGatewayEnum.PAYPAL })
    }, []);

    useEffect(() => {
        console.log("DATA", config?.data)
        setEnv(config?.data == EnvConfig.PRODUCTION ? true : false)
    }, [env]);


    const onHandleConfigPaypalEnv = () => {
        setEnvConfigPaypal(!envConfigPaypal)

        const paypalEnv = !envConfigPaypal ? EnvConfig.PRODUCTION : EnvConfig.DEVELOPMENT
        getPaypalConfig({ env: paypalEnv, gateway: PaymentGatewayEnum.PAYPAL })
    }

    const onHandleChangeEnv = async (e: boolean) => {
        console.log('[onHandleChangeEnv] ON', env, e);
        setIsEnvLoading(true)

        const systemEnv = e ? EnvConfig.PRODUCTION : EnvConfig.DEVELOPMENT
        setEnv(e);
        console.log('[onHandleChangeEnv] AFTER CONVERT', systemEnv);

        const response = await setConfigEnv({ env: systemEnv });
        console.log("RESPonse", response)

        if (response) {
            message.success('Update success!');
        } else {
            message.error('Update Error!');
        }
        setIsEnvLoading(false)
    }

    const onHandleUpdateConfigPaypal = async (values: PaypalConfigParams) => {
        setIsConfigLoading(true)
        console.log('[onHandleUpdateConfigPaypal] ON', values);
        const paypalEnv = envConfigPaypal ? EnvConfig.PRODUCTION : EnvConfig.DEVELOPMENT

        const response = await setPaypalConfigEnv(
            {
                ...values,
                gateway: PaymentGatewayEnum.PAYPAL, env: paypalEnv
            }
        );

        if (response) {
            run();
            message.success('Update success!');
        } else {
            message.error('Update Error!');
        }

        setIsConfigLoading(false)

    }

    return (
        <div style={{ padding: '24px' }}>
            <div className='config-env'>
                <h3>Current Environment - {env ? EnvConfig.PRODUCTION : EnvConfig.DEVELOPMENT}</h3>
                {loading ? <Spin size='small'></Spin> : <Switch defaultChecked={config?.data == EnvConfig.PRODUCTION ? true : false} onChange={(e) => onHandleChangeEnv(e)} loading={isEnvLoading} />}
            </div>

            <div className='config-paypal'>
                <div className='config-env'>
                    <h3>Paypal Config - {envConfigPaypal ? EnvConfig.PRODUCTION : EnvConfig.DEVELOPMENT}</h3>
                    {isConfigLoading ? <Spin size='small'></Spin> : <Switch defaultChecked={envConfigPaypal} onChange={onHandleConfigPaypalEnv}></Switch>}

                </div>


                <div className='config-form'>
                    {paypalConfigLoading ? <div className='spin-loadconfig'>
                        <Spin size={'large'} />
                    </div> : <Form
                        form={form}
                        name="normal_subscription"
                        fields={[
                            {
                                name: ['clientId'],
                                value: configPaypal?.data?.clientId ?? ''
                            },
                            {
                                name: ['clientSecret'],
                                value: configPaypal?.data?.clientSecret ?? ''
                            },
                            {
                                name: ['baseUrl'],
                                value: configPaypal?.data?.baseUrl ?? ''
                            },
                            {
                                name: ['merchantId'],
                                value: configPaypal?.data?.merchantId ?? ''
                            },
                        ]}
                        className=""
                        layout="vertical"
                        autoComplete="off"
                        onFinish={onHandleUpdateConfigPaypal}
                    >
                        <Form.Item name="clientId" label="Client ID" rules={[{ required: true, message: "Please Input Client ID" }]}>
                            <Input placeholder="Input Client ID" value={""} />
                        </Form.Item>

                        <Form.Item name="clientSecret" label="Client Secret" rules={[{ required: true, message: "Please Input Client Secret" }]}>
                            <Input placeholder="Input Client Secret" />
                        </Form.Item>
                        <Form.Item name="baseUrl" label="Base Url" rules={[{ required: true, message: "Please Input Base Url" }]} >
                            <Input placeholder="Input Base Url" />
                        </Form.Item>

                        <Form.Item name="merchantId" label="Merchant ID" rules={[{ required: true, message: "Please Input Merchant ID" }]}>
                            <Input placeholder="Input Merchant ID" />
                        </Form.Item>
                        <div style={{ display: 'flex', justifyContent: "flex-end" }}>
                            <Button style={{ marginLeft: "0 auto" }} htmlType='submit' type='primary' loading={isConfigLoading}> Update</Button>
                        </div>
                    </Form>
                    }
                </div>
            </div>
        </div>
    );
};
