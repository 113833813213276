import { useGetListKeywordsOfApp } from '@services/manage-keyword/manage-keyword';
import { PageProps } from '@types';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TableKeyword } from './components/TableKeyword';
import './styles.scss';
import { useQuery } from '../../hooks/useQuery';
import { SVGIcon } from '@components';

export const PartnerAppKeyword: React.FC<PageProps> = () => {
    const { data: listPartnerKeywords, loading, run, refresh } = useGetListKeywordsOfApp();
    const param = useParams<{ appId: string }>()
    const [sortOrder, setSortOrder] = useState<'ascend' | 'descend' | undefined>();
    const [currentPg, setCurrentPg] = useState<number>(0)
    const history = useHistory()
    const query = useQuery()

    useEffect(() => {
        const loadPage = query?.get('page')
        let page = 0
        if (!loadPage) page = 1
        else page = +loadPage

        history.push('?page=' + page)
        setCurrentPg(page - 1)
        run({
            page: page - 1,
            searcher: {},
            sorter: {},
            appId: param.appId
        });
    }, []);

    const onHandleChangePage = (e: number) => {
        setCurrentPg(e)
        const _e = e + 1
        history.push('?page=' + _e)
    }

    return (
        <div style={{ padding: '24px' }}>
            <h2 style={{ display: 'flex', alignItems: 'center' }}>
                <span onClick={() => history.push('/admin/apps?page=1')} style={{ cursor: 'pointer' }}>
                    <SVGIcon name="back" width={30} height={30} style={{ marginTop: 4 }} />
                </span>
                Partner App Keyword
            </h2>
            <TableKeyword
                onPageChange={run}
                dataSource={listPartnerKeywords?.data?.hits ?? []}
                isLoading={loading}
                onCurrentPage={(e) => onHandleChangePage(e)}
                currentPage={currentPg}
                sortOrder={sortOrder}
                pagination={listPartnerKeywords?.data.pagination}
                onFilter={({ page, sorter, pageSize }) => {
                    setSortOrder(!!sorter.title ? (sorter.title === 'asc' ? 'ascend' : 'descend') : undefined);
                    run({
                        page,
                        sorter,
                        pageSize,
                        appId: param.appId
                    });
                }}
                isDisabled 
            />
        </div>
    );
};
