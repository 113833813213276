import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { ErrorBoundary } from '@components';
import { ROUTERS, ROUTERS_MAIN } from '@constants';

import api from '@services/api';
import { RoleEnum } from '@types';
import { RouteProps } from './types';

export const PrivateRouter: React.FC<RouteProps> = (props) => {
  const { render, ...res } = props;
  const userData = api.getAuthenticated();

  const renderPath = () => {
    if (!userData) return ROUTERS.LOGIN
    if (userData.role == RoleEnum.ROOT) return ROUTERS_MAIN.PARTNER
    else ROUTERS_MAIN.USER
  }
  return (
    <Route
      {...res}
      render={(renderProps) =>
        userData ? (
          <ErrorBoundary>{render(renderProps)}</ErrorBoundary>
        ) : (
          <Redirect
            to={{
              pathname: ROUTERS.LOGIN,
              state: { from: renderProps.location },
            }}

          />
        )
      }
    />
  );
};

{/* <Route exact path="/">
              <Redirect
                to={
                  !userData?.role
                    ? '/login'
                    : userData.role == RoleEnum.ROOT
                      ? '/admin/partners'
                      : ROUTERS_MAIN.PARTNER_SUBCRIPTION
                }
              />
            </Route> */}