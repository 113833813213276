import { lazy } from 'react';

import { ROUTERS, ROUTERS_MAIN } from '@constants';
import { RouteItem } from '@types';
import { PartnerSubscription } from '@pages/management-subscription/PartnerSubscription';
import { PartnerKeyword } from '@pages/management-keyword/PartnerKeyword';
import { PartnerAppKeyword } from '@pages/management-keyword/PartnerAppKeyword';
import { PartnerApp } from '@pages/management-apps/PartnerApp';
import ManagementConfig from '@pages/manage-config';
import ManagementTx from '@pages/management-transaction';
import { PartnerTx } from '@pages/management-transaction/PartnerTx';
import { AddApp } from '@pages/management-apps/AddApp';
import { KeywordByApp } from '@pages/management-apps/KeywordByApp';
import { UserGaConnect } from '@pages/management-apps/UserGaConnect';
import { UserPartnerConnect } from '@pages/management-apps/UserPartnerConnect';
import { AdminPartnerAppKeyword } from '@pages/management-keyword/AdminPartnerAppKeyword';
import { TableListApps } from '@pages/management-user/components/list-app-partner-member/TableListApps';

const Page404 = lazy(() => import('@pages/page404'));
const Page500 = lazy(() => import('@pages/page500'));
const Dashboard = lazy(() => import('@pages/dashboard'));
const ManagementApp = lazy(() => import('@pages/management-apps'));
const ManageCategory = lazy(() => import('@pages/manage-categories'));
const ManageCollection = lazy(() => import('@pages/manage-collections'));
const ManagementKeyword = lazy(() => import('@pages/management-keyword'));
const ManagementPartner = lazy(() => import('@pages/management-partner'));
const ManagementSubscription = lazy(() => import('@pages/management-subscription'));
const ManagementUser = lazy(() => import('@pages/management-user'));
const Login = lazy(() => import('@pages/auth'));
const ManagementBlog = lazy(() => import('@pages/management-blogs'));

export const routes: RouteItem[] = [
  { path: ROUTERS.NOT_FOUND, component: Page404 },
  { path: ROUTERS.UNKNOWN_ERROR, component: Page500 },
  { path: ROUTERS.LOGIN, component: Login, guard: false },
];

export const routesMain: RouteItem[] = [
  { path: ROUTERS_MAIN.DASHBOARD, component: Dashboard, guard: true },
  { path: ROUTERS_MAIN.APP, component: ManagementApp, guard: true },
  { path: ROUTERS_MAIN.KEYWORD, component: ManagementKeyword, guard: true },
  { path: ROUTERS_MAIN.PARTNER, component: ManagementPartner, guard: true },
  { path: ROUTERS_MAIN.USER, component: ManagementUser, guard: true },
  { path: ROUTERS_MAIN.SUBCRIPTION, component: ManagementSubscription, guard: true },
  { path: ROUTERS_MAIN.PARTNER_SUBCRIPTION, component: PartnerSubscription, guard: true },
  { path: ROUTERS_MAIN.PARTNER_KEYWORD, component: PartnerKeyword, guard: true },
  { path: ROUTERS_MAIN.PARTNER_APP_KEYWORD, component: PartnerAppKeyword, guard: true },
  { path: ROUTERS_MAIN.ADMIN_PARTNER_APP_KEYWORD, component: AdminPartnerAppKeyword, guard: true },
  { path: ROUTERS_MAIN.PARTNER_APP, component: PartnerApp, guard: true },
  { path: ROUTERS_MAIN.CONFIG_ENV, component: ManagementConfig, guard: true },
  { path: ROUTERS_MAIN.SUB_TX, component: ManagementTx, guard: true },
  { path: ROUTERS_MAIN.SUB_PARTNER_TX, component: PartnerTx, guard: true },
  { path: ROUTERS_MAIN.PARTNER_ADD_APPS, component: AddApp, guard: true },
  { path: ROUTERS_MAIN.KEYWORD_BY_APP, component: KeywordByApp, guard: true },
  { path: ROUTERS_MAIN.USER_CONNECT_GA_BY_APP, component: UserGaConnect, guard: true },
  { path: ROUTERS_MAIN.USER_CONNECT_PARTNER_BY_APP, component: UserPartnerConnect, guard: true },
  { path: ROUTERS_MAIN.CATEGORY, component: ManageCategory, guard: true },
  { path: ROUTERS_MAIN.COLLECTION, component: ManageCollection, guard: true },
  { path: ROUTERS_MAIN.BLOG, component: ManagementBlog, guard: true },
  { path: ROUTERS_MAIN.LIST_APP_PARTNER_MEMBER, component: TableListApps, guard: true },
];
