import React, { useEffect } from 'react';
import './styles.scss';
import { Modal } from 'antd';
export const TRANSITION = 300;

export type OverlayProps = any;

export const Overlay: React.FC<OverlayProps> = ({ children, className, ...props }) => {
  const blur = (isBlur: boolean) => {
    const root = document.getElementById('root');
    if (!root) return;
    root.style.transition = isBlur ? `${TRANSITION}ms` : '';
    root.style.filter = isBlur ? 'blur(4px)' : '';
  };

  useEffect(() => {
    blur(props.open);
  }, [props.open]);

  useEffect(() => () => blur(false), []);

  return (
    <Modal className='overlay' centered open={props.open} footer={null} closeIcon={null} destroyOnClose={true}>
      {children}
    </Modal>
  );
};
