import { AddAppParams } from '@types';
import { Form, Input, Modal, Select } from 'antd';
import { useEffect, useState } from 'react';

interface Props {
  isOpen: boolean;
  apps: Array<{ app_id: string }>;
  onOk: (e: AddAppParams) => void;
  onCancel: () => void;
  username: string | null;
  loadingAddApp: boolean;
}

export const ModalAddApps = ({ isOpen, apps, onOk, onCancel, username, loadingAddApp }: Props) => {
  const [form] = Form.useForm();
  const [app, setApp] = useState<string>('');

  useEffect(() => {
    setApp(apps?.[0]?.app_id ?? '');
  }, [apps, isOpen]);

  useEffect(() => {
    form.resetFields();
  }, [isOpen]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        onOk({
          username: values.username,
          appId: values.appId,
        });
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const options =
    apps?.map((item) => ({
      value: item.app_id,
      label: item.app_id,
    })) ?? [];

  return (
    <Modal
      title="Add Apps For Partner Member"
      centered
      className="update-sub"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={loadingAddApp}
      destroyOnClose={true}
    >
      <Form
        form={form}
        name="username"
        className="app-keyword"
        layout="vertical"
        initialValues={{
          username: username || '',
        }}
        autoComplete="off"
      >
        <Form.Item name="username" label="User name">
          <Input value={username ?? ''} readOnly placeholder="Input username" type="text" disabled />
        </Form.Item>
        <Form.Item name="appId" label="App ID" rules={[{ required: true, message: 'Please select an app!' }]}>
          <Select
            showSearch
            value={app}
            style={{ width: '100%' }}
            onChange={(value) => {
              setApp(value);
            }}
            options={options}
            placeholder="Search and select an app"
            filterOption={(input, option) => option?.label?.toLowerCase().includes(input.toLowerCase()) ?? false}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
