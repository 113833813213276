import { RECORDS_PER_PAGE } from '@constants';
import api from '@services/api';
import {
  ParamsSettings,
  PlatformEnum,
  ResponseDetailSuccess,
  ResponseSuccess,
  UpdateProductPlanRequestParam,
} from '@types';
import { getSearchParams, getSortParams } from '@utils/helper';
import { useRequest } from 'ahooks';
import {
  PartnerCreateSubscription,
  PartnerSubscriptionDocument,
  ProductPlanDocument,
  UpdateSubscriptionParam,
} from 'src/types/admin-subscription';

export const rootGetListTransactions = async ({
  page,
  sorter,
  searcher,
  pageSize,
}: ParamsSettings<any>): Promise<ResponseSuccess<any>> => {
  const size = pageSize ? pageSize : RECORDS_PER_PAGE;
  const response: ResponseSuccess<any> = await api.getListDocument({
    url: '/admin/root/payments/transactions',
    params: {
      limit: size,
      offset: page * size,
      ...getSortParams(sorter),
      ...getSearchParams(searcher),
    },
  });
  return response;
};

export const useRootGetListTransaction = () => {
  return useRequest<ResponseSuccess<any>, [ParamsSettings<any>]>(rootGetListTransactions, {
    manual: true,
  });
};

export const getListTransactions = async ({
  page,
  sorter,
  searcher,
  pageSize,
}: ParamsSettings<any>): Promise<ResponseSuccess<any>> => {
  const size = pageSize ? pageSize : RECORDS_PER_PAGE;
  const response: ResponseSuccess<any> = await api.getListDocument({
    url: '/admin/payments/transactions',
    params: {
      limit: size,
      offset: page * size,
      ...getSortParams(sorter),
      ...getSearchParams(searcher),
    },
  });
  return response;
};

export const useGetListTransaction = () => {
  return useRequest<ResponseSuccess<any>, [ParamsSettings<any>]>(getListTransactions, {
    manual: true,
  });
};
