import { Suspense } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { MainLayout } from '@layout';
import { PrivateRouter } from './components';
import { routes, routesMain } from './config';
import api from '@services/api';
import { ROUTERS, ROUTERS_MAIN } from '@constants';
import { RoleEnum } from '@types';



export const AppRouter: React.FC = () => {
  const userData = api.getAuthenticated();
  const renderPath = () => {
    if (userData.role == RoleEnum.ROOT) return ROUTERS_MAIN.PARTNER
    else ROUTERS_MAIN.USER
  }
  return <BrowserRouter>
    <Suspense fallback={false}>
      <Switch>
        {routes.map(({ path, component }, i) => (
          <Route key={i} exact path={path} component={component} />
        ))}

        <PrivateRouter path="/" render={() => {
          return (<MainLayout>
            {routesMain.map((route) =>
              <Route key={route.path} exact path={route.path} component={route.component} />
            )}
          </MainLayout>)
        }} />


      </Switch>
    </Suspense>
  </BrowserRouter>
}