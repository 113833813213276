import { RECORDS_PER_PAGE } from '@constants';
import api from '@services/api';
import { ParamsSettings, ResponseDetailSuccess, ResponseSuccess, RoleEnum } from '@types';
import { getSearchApps, getSearchParams, getSortApps, getSortParams } from '@utils/helper';
import { useRequest } from 'ahooks';

const userData = api.getAuthenticated();

export const getListApps = async ({
  page,
  sorter,
  searcher,
  pageSize,
}: ParamsSettings<any>): Promise<ResponseSuccess<any>> => {
  const size = pageSize ? pageSize : RECORDS_PER_PAGE;
  console.log("======sorter", sorter)
  const response: ResponseSuccess<any> = await api.getListDocument({
    url: '/admin/partner/app-connects',
    params: {
      limit: size,
      offset: page * size,
      ...getSortParams({ created_at: 'desc', ...sorter }, false),
      ...getSearchParams(searcher),
    },
  });
  return response;
};

export const getAllApps = async ({
  page,
  sorter,
  searcher,
  pageSize,
}: ParamsSettings<any>): Promise<ResponseSuccess<any>> => {
  const size = pageSize ? pageSize : RECORDS_PER_PAGE;
  const response: ResponseSuccess<any> = await api.getListDocument({
    url: '/admin/root/all-apps',
    params: {
      limit: size,
      offset: page * size,
      ...getSortApps({ ...sorter }, false),
      ...getSearchApps(searcher),
    },
  });
  return response;
};

export const useGetListApps = () => {
  return useRequest<ResponseSuccess<any>, [ParamsSettings<any>]>(
    userData.role === RoleEnum.ROOT ? getAllApps : getListApps,
    { manual: true },
  );
};

export const getListAppNewest = async ({
  page,
  sorter,
  searcher,
  pageSize,
}: ParamsSettings<any>): Promise<ResponseSuccess<any>> => {
  const size = pageSize ? pageSize : RECORDS_PER_PAGE;
  const response: ResponseSuccess<any> = await api.getListDocument({
    url: '/admin/partner/apps/news',
    params: {
      limit: size,
      offset: page * size,
      ...getSortParams({ created_at: 'desc', ...sorter }, false),
      ...getSearchParams(searcher),
    },
  });
  return response;
};

export const useGetListAppNewest = () => {
  return useRequest<ResponseSuccess<any>, [ParamsSettings<any>]>(getListAppNewest, { manual: true });
};

export const getAppByUsers = async (): Promise<ResponseDetailSuccess<Array<{ app_id: string }>>> => {
  const response: ResponseDetailSuccess<Array<{ app_id: string }>> = await api.getListDocument({
    url: '/admin/partner/my-app',
    params: {},
  });
  return response;
};

export const useGetAppByUsers = () => {
  return useRequest<ResponseDetailSuccess<Array<{ app_id: string }>>, []>(getAppByUsers, {
    manual: true,
  });
};

export const rootGetListApps = async ({
  page,
  sorter,
  searcher,
  pageSize,
  username,
}: any): Promise<ResponseSuccess<any>> => {
  const size = pageSize ? pageSize : RECORDS_PER_PAGE;
  const response: ResponseSuccess<any> = await api.getListDocument({
    url: `/admin/root/partner/${username}/app-connects`,
    params: {
      limit: size,
      offset: page * size,
      ...getSortParams({ created_at: 'desc', ...sorter }, false),
      ...getSearchParams(searcher),
    },
  });
  console.log('RESPONSE', response);
  return response;
};

export const useRootGetListApps = () => {
  return useRequest<ResponseSuccess<any>, [any]>(rootGetListApps, { manual: true });
};

export const deleteAppForPartner = async (app_id: string): Promise<ResponseDetailSuccess<any>> => {
  const response: ResponseDetailSuccess<any> = await api.postDocument({
    url: `/admin/partner/my-app/delete`,
    data: {
      app_id,
    },
  });

  return response as ResponseDetailSuccess<any>;
};

export const deleteAppByRootforPartner = async (
  appId: string,
  hash_user?: string,
): Promise<ResponseDetailSuccess<any>> => {
  const response: ResponseDetailSuccess<any> = await api.postDocument({
    url: `/admin/root/partner-app/delete`,
    data: {
      hash_user,
      app_id: appId,
    },
  });

  return response as ResponseDetailSuccess<any>;
};

export const deleteKeywordByRootforPartner = async (
  appId: string,
  hash_user?: string,
  keyword?: string,
): Promise<ResponseDetailSuccess<any>> => {
  const response: ResponseDetailSuccess<any> = await api.postDocument({
    url: `/admin/root/partner-keyword/delete`,
    data: {
      hash_user,
      keyword,
      app_id: appId,
    },
  });

  return response as ResponseDetailSuccess<any>;
};

export const updateAppStatusForPartner = async (
  app_id: string,
  ga_connected: boolean,
): Promise<ResponseDetailSuccess<any>> => {
  const response: ResponseDetailSuccess<any> = await api.postDocument({
    url: `/admin/partner/my-app/status`,
    data: {
      app_id,
      ga_connected,
    },
  });

  return response as ResponseDetailSuccess<any>;
};

export const rootUpdateAppStatusForPartner = async (
  app_id: string,
  ga_connected: boolean,
  username: string,
): Promise<ResponseDetailSuccess<any>> => {
  const response: ResponseDetailSuccess<any> = await api.postDocument({
    url: `/admin/root/partner/my-app/status`,
    data: {
      app_id,
      ga_connected,
      username,
    },
  });

  return response as ResponseDetailSuccess<any>;
};

export const adminPartnerAddNewApps = async (
  app_id: string,
  is_owner: boolean,
): Promise<ResponseDetailSuccess<any>> => {
  const response: ResponseDetailSuccess<any> = await api.postDocument({
    url: `/admin/partner/apps/news`,
    data: {
      app_id,
      is_owner,
    },
  });

  return response as ResponseDetailSuccess<any>;
};

export const getUsersKeywordsByApp = async ({ page, sorter, pageSize, app_id }: any): Promise<ResponseSuccess<any>> => {
  const size = pageSize ? pageSize : RECORDS_PER_PAGE;
  const response: ResponseSuccess<any> = await api.getListDocument({
    url: `/admin/root/users-keywords/${app_id}/apps`,
    params: {
      limit: size,
      offset: page * size,
      ...getSortParams({ created_at: 'desc', ...sorter }, false),
    },
  });
  console.log('RESPONSE', response);
  return response;
};

export const useGetUsersKeywordsByApp = () => {
  return useRequest<ResponseSuccess<any>, [any]>(getUsersKeywordsByApp, { manual: true });
};

export const getUserConnectGa = async ({
  page,
  sorter,
  pageSize,
  app_id,
}: any): Promise<ResponseDetailSuccess<any>> => {
  const size = pageSize ? pageSize : RECORDS_PER_PAGE;
  const response: ResponseDetailSuccess<any> = await api.getListDocument({
    url: `/admin/root/users-connect-ga/${app_id}/apps`,
    params: {
      limit: size,
      offset: page * size,
      ...getSortParams({ created_at: 'desc', ...sorter }, false),
    },
  });
  console.log('RESPONSE', response);
  return response;
};

export const useGetUserConnectGa = () => {
  return useRequest<ResponseDetailSuccess<any>, [any]>(getUserConnectGa, { manual: true });
};

export const getUserConnectPartner = async ({
  page,
  sorter,
  pageSize,
  app_id,
}: any): Promise<ResponseDetailSuccess<any>> => {
  const size = pageSize ? pageSize : RECORDS_PER_PAGE;
  const response: ResponseDetailSuccess<any> = await api.getListDocument({
    url: `/admin/root/users-connect-shopify/${app_id}/apps`,
    params: {
      limit: size,
      offset: page * size,
      ...getSortParams({ created_at: 'desc', ...sorter }, false),
    },
  });
  console.log('RESPONSE', response);
  return response;
};

export const useGetUserConnectPartner = () => {
  return useRequest<ResponseDetailSuccess<any>, [any]>(getUserConnectPartner, { manual: true });
};
