import axios, { AxiosInstance, AxiosResponse } from 'axios';

import { ERROR_CODE, KEYS } from '@constants';
import { appActions, userActions } from '@redux';
import { AdminPartnerDocumentType, PartnerConnectorDocument } from '@types';
import { notification } from 'antd';

let isRefreshingAccessToken = false;
const handleInterceptor = (caller: AxiosInstance) => {
  caller.interceptors.request.use(
    (config) => config,
    (error) => Promise.reject(error),
  );

  // Add a response interceptor
  caller.interceptors.response.use(
    (response) => {
      const originalRequest = response.config;
      if (response.status === ERROR_CODE.EXPIRE_TOKEN && originalRequest) {
        // eslint-disable-next-line
        const { store } = require('../store');
        store.dispatch(userActions.logout());
        store.dispatch(appActions.hideLoading());

        return response;
      } else {
        return response;
      }
    },
    (error) => Promise.reject(error),
  );
};

class Api {
  private caller!: AxiosInstance;

  static instance?: Api;

  static getInstance() {
    if (!this.instance) this.instance = new Api();
    return this.instance;
  }

  constructor() {
    this.init();
  }

  private init() {
    this.caller = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        'Content-type': 'application/json',
      },
    });
    handleInterceptor(this.caller);
    const token = localStorage.getItem(KEYS.TOKEN);
    if (token) this.caller.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  }

  //> Handle Interceptor

  //> Handle
  public handleResponse(response: AxiosResponse) {
    if (response.data.code !== ERROR_CODE.HTTP_SUCCESS) {
      // message.error('Can not excute action! Please try again!');
      return response.data;
    }
    return response.data;
  }
  public handleResponseLogin(response: AxiosResponse) {
    if (response.data.code !== ERROR_CODE.HTTP_SUCCESS) {
      notification.error({
        message: 'Wrong username or password!',
        duration: 3,
        placement: 'topRight',
      });
      return response.data;
    }
    return response.data;
  }

  public handleResponseNoError(response: AxiosResponse) {
    if (response.data.code !== ERROR_CODE.HTTP_SUCCESS) {
      return response.data;
    }
    return response.data;
  }

  // > API
  public setToken(token: string) {
    localStorage.setItem(KEYS.TOKEN, token);
    if (token) this.caller.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  }

  public setAuthenticated(admin: AdminPartnerDocumentType) {
    localStorage.setItem(KEYS.ADMIN, JSON.stringify(admin));
  }

  public removeAuthenticated() {
    localStorage.removeItem(KEYS.ADMIN);
  }

  public refreshToken(token: string) {
    localStorage.setItem(KEYS.TOKEN, token);
    if (token) this.caller.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  }

  public getToken() {
    return localStorage.getItem(KEYS.TOKEN);
  }

  public getAuthenticated() {
    const storage = localStorage.getItem(KEYS.ADMIN) as string;
    return JSON.parse(storage) as AdminPartnerDocumentType;
  }

  public setLoginInfo(username: string) {
    localStorage.setItem(KEYS.USER, username);
  }

  public getCurrentUser() {
    return localStorage.getItem(KEYS.USER);
  }

  public isAuthenticated() {
    return this.getToken() !== undefined && this.getToken() !== null;
  }

  public removeToken() {
    localStorage.removeItem(KEYS.TOKEN);
    this.caller.defaults.headers.common['Authorization'] = '';
  }

  public login(params: { username: string; password: string }) {
    return this.caller.post<PartnerConnectorDocument>('/auth/admin/login', params).then(this.handleResponseLogin);
  }

  public getProfile() {
    return this.caller.get<AdminPartnerDocumentType>('/admin/profile').then(this.handleResponseNoError);
  }

  public getPartnerConnectorManagement<T>({ url, params }: any) {
    return this.caller.get<PartnerConnectorDocument>(url, { params }).then(this.handleResponse);
  }

  public getListDocument<T>({ url, params }: any) {
    return this.caller.get<T>(url, { params }).then(this.handleResponse);
  }

  public postDocument<T>({ url, data }: any) {
    return this.caller.post<T>(url, data).then(this.handleResponse);
  }

  public putDocument<T>({ url, data }: any) {
    return this.caller.put<T>(url, data).then(this.handleResponse);
  }
}

const api = Api.getInstance();

export default api;
