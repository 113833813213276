import { MenuOutlined } from '@ant-design/icons';
import { Button, Drawer, Menu } from 'antd';
import React, { useState } from 'react';
import './styles.scss';
import { AdminPartnerDocumentType } from '@types';
import { SidebarItemTypes } from '../Sidebar/types';
import { SIDEBAR_ITEMS } from '../Sidebar/sidebar.constant';
import { Logo, SVGIcon } from '@components';
import { Link } from 'react-router-dom';

interface NavbarProps {
  userData: AdminPartnerDocumentType;
}

export const Navbar = (props: NavbarProps) => {
  const [visible, setVisible] = useState(false);
  const items2 = SIDEBAR_ITEMS.map((item: SidebarItemTypes) => {
    const icon = <SVGIcon name={item.icon} />;
    return {
      key: item.path,
      icon,
      label: item.label,
      path: item.path,
      isShow: item.roles.includes(props.userData.role) ?? false,
    };
  });

  return (
    <nav className="navbar">
      <Button className="menu" type="primary" icon={<MenuOutlined />} onClick={() => setVisible(true)} />
      <Drawer
        title={<Logo />}
        placement="left"
        onClick={() => setVisible(false)}
        onClose={() => setVisible(false)}
        visible={visible}
        closeIcon={false}
        width={300}
      >
        <Menu theme="light" mode="inline">
          {items2.map((item) => {
            if (!item.isShow) return null;
            return (
              <Menu.Item key={item.key} className="side-menu_item">
                {item.icon}
                <span>{item.label}</span>
                <Link to={item.path} />
              </Menu.Item>
            );
          })}
        </Menu>
      </Drawer>
    </nav>
  );
};
