import { PageProps } from '@types';
import {  useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import './styles.scss';
import { useQuery } from '../../hooks/useQuery';
import { SVGIcon } from '@components';
import { useGetUsersKeywordsByApp } from '@services/manage-app/manage-app';
import { useMount } from 'ahooks';
import { TableKeywordApp } from './components/TableKeywordApp';

export interface KeywordsDocument {
    app_id: string;
    created_at: string;
    hash_keyword: string;
    hash_user: string;
    key: {
        text: string
    }[]
    user: {
        username:string;
        hash_user:string;
    }[]
  }

export const KeywordByApp: React.FC<PageProps> = () => {
    const { data: listKeywords, loading, run, refresh } = useGetUsersKeywordsByApp();
    const param = useParams<{ app: string }>()
    const [currentPg, setCurrentPg] = useState<number>(0)
    const history = useHistory();
    const query = useQuery()
    const pageValue = query?.get('old-page')
    const searchValue = query?.get('search')

    useMount(() => {
        const loadPage = query?.get('page')
        let page = 0
        if (!loadPage) page = 1
        else page = +loadPage
        history.push(`?page=${page}&old-page=${pageValue}${searchValue ? `&search=${searchValue}` : ''}`)
        setCurrentPg(page - 1)
        run({
          page: page -1 ,
          sorter: {},
          page_size: 10,
          app_id: param.app
        });
    });

    const onHandleChangePage = (e: number) => {
        setCurrentPg(e)
        const _e = e + 1
        history.push(`?page=${_e}&old-page=${pageValue}${searchValue ? `&search=${searchValue}` : ''}`)
    }

    return (
        <div style={{ padding: '24px' }}>
            <h2 style={{ display: 'flex', alignItems: 'center' }}>
                <span onClick={() => history.push(`/admin/apps?page=${pageValue}${searchValue ? `&search=${searchValue}`: ''}`)} style={{ cursor: 'pointer' }}>
                    <SVGIcon name="back" width={30} height={30} style={{ marginTop: 4 }} />
                </span>
                Keyword By Apps
            </h2>
            <div className="filter-container_transaction">
                <div>
                    <span>Total Records:</span> <span>{listKeywords?.data?.pagination?.totalRows ?? 0}</span>
                </div>
            </div>
            <TableKeywordApp
                onPageChange={run}
                dataSource={listKeywords ? listKeywords?.data?.hits : []}
                isLoading={loading}
                onCurrentPage={(e) => onHandleChangePage(e)}
                pagination={listKeywords?.data.pagination}
                currentPage={currentPg}
                onFilter={({ page, sorter, pageSize }) => {
                  run({
                    page,
                    sorter,
                    pageSize,
                    app_id: param.app
                  });
                }}
                 />
        </div>
    );
};
