import { AntTable} from '@components';
import { ParamsSettings } from '@types';
import { getPaginationFromAntdTable, getSorterParamsFromAntdTable } from '@utils/helper';
import { Col, Row } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import { AddKeywordParams } from 'src/types/admin-keyword';

interface Props {
  dataSource?: any[];
  isLoading?: boolean;
  pagination?: {
    totalRows?: number;
  };
  sortOrder?: 'ascend' | 'descend';
  currentPage: number;
  onFilter?: (params: ParamsSettings<any>) => void;
  onRemove?: (e: AddKeywordParams, currentPage: number) => void;
  onRefresh?: () => void;
  onPageChange: (e: any) => void;
  onCurrentPage: (e: number) => void;
}

export const TableUserConnect = ({ dataSource = [], isLoading, pagination, currentPage, onFilter, onRemove, onCurrentPage, }: Props) => {
  const [pageSize, setPageSize] = useState<number>(10)

  const columns: ColumnsType<any> = [
    {
      key: 'username',
      dataIndex: 'username',
      title: 'Username',
      render: (value: string, item) => <h3>{ item.username ?  item.username : '-'}</h3>,
      width: 200,
    },
    {
      key: 'created_at',
      dataIndex: 'created_at',
      title: 'Created At',
      render: (value: string, item) => <h3>{ item.created_at ?  item.created_at : '-'}</h3>,
      width: 200,
    },
  ];

  return (
    <div style={{ margin: '24px 0' }}>
      <AntTable
        columns={columns}
        dataSource={dataSource}
        rowKey={(r) => r._id}
        loading={isLoading}
        pagination={{
          pageSize: pageSize,
          total: pagination?.totalRows,
          current: currentPage + 1,
          pageSizeOptions: [10]
        }}
        onChange={(pagination, _, sorter, extra) => {
          onCurrentPage(getPaginationFromAntdTable({ pagination, extra }))
          onFilter?.({
            page: getPaginationFromAntdTable({ pagination, extra }),
            sorter: getSorterParamsFromAntdTable({ sorter }),
            searcher: {},
            pageSize: pagination.pageSize
          });
          setPageSize(pagination.pageSize ?? pageSize)
        }}
      />
    </div>
  );
};
