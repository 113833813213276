import { useRootGetListApps, deleteAppByRootforPartner } from '@services/manage-app/manage-app';
import { PageProps } from '@types';
import { useMount } from 'ahooks';
import { message } from 'antd';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '../../hooks/useQuery';
import { FilterApps } from './components/FilterApps';
import { TableApps } from './components/TableApps';
import './styles.scss';
import { SVGIcon } from '@components';

export const PartnerApp: React.FC<PageProps> = () => {
    const { data: listApps, loading, run, refresh } = useRootGetListApps();
    const [searchValue, setSearchValue] = useState<string>('');
    const [sortOrder, setSortOrder] = useState<'ascend' | 'descend' | undefined>();
    const param = useParams<{ username: string }>()
    const [currentPage, setCurrentPage] = useState<number>(0)
    const [selectStatus, setSelectStatus] = useState<string>('ALL');
    const history = useHistory()
    const query = useQuery()

    useMount(() => {
        const loadPage = query?.get('page')
        let page = 0
        if (!loadPage) page = 1
        else page = +loadPage

        history.push('?page=' + page)
        setCurrentPage(page - 1)
        run({
            page: page - 1,
            searcher: {},
            sorter: {},
            username: param.username
        });
    });

    const onHandleRemoveApp = async (appId: string, currentPg: number, hash_user?: string,) => {
        const response = await deleteAppByRootforPartner(appId, hash_user);
        console.log("RESPONSE", response)
        if (response.code == 0) {
          message.success('Remove success!');
          run({
            page: currentPg,
            searcher: {
                app_id: { value: searchValue.trim(), operator: 'contains' },
                is_owner: { value: selectStatus == 'ALL' ? undefined : 'true', operator: onChangeStatusOperator(selectStatus) },
            },
            sorter: {},
            username: param.username
          });
        } else {
          message.error('Remove error!')
        }
      }

    const onHandleChangePage = (e: number) => {
        setCurrentPage(e)
        const _e = e + 1
        history.push('?page=' + _e)
    }

    const onChangeStatusOperator = (value: string) => {
        if (value == 'FALSE') return 'ne'
        return 'eq'

    }

    return (
        <div style={{ padding: '24px' }}>
            <h2 style={{ display: 'flex', alignItems: 'center' }}>
                <span onClick={() => history.push('/admin/partners?page=1')} style={{ cursor: 'pointer' }}>
                    <SVGIcon name="back" width={30} height={30} style={{ marginTop: 4 }} />
                </span>
                {param.username} partner app list
            </h2>
            <FilterApps
                onSearch={(value) => {
                    run({
                        page: 0,
                        searcher: {
                            app_id: { value, operator: 'contains' },
                            is_owner: { value: selectStatus == 'ALL' ? undefined : 'true', operator: onChangeStatusOperator(selectStatus) },
                        },
                        sorter: {
                            created_at: sortOrder === 'ascend' ? 'asc' : sortOrder === 'descend' ? 'desc' : undefined,
                        },
                        username: param.username
                    });
                    setSearchValue(value);
                    // setSortOrder(undefined);
                    setCurrentPage(0);
                }}
                onSelectStatus={(value) => {
                    run({
                        page: 0,
                        searcher: {
                            app_id: { value: searchValue ?? undefined, operator: 'contains' },
                            is_owner: { value: value == 'ALL' ? undefined : 'true', operator: onChangeStatusOperator(value) },
                        },
                        sorter: {
                            created_at: sortOrder === 'ascend' ? 'asc' : sortOrder === 'descend' ? 'desc' : undefined,
                        },
                        username: param.username
                    });
                    setSortOrder(!!value ? (value === 'asc' ? 'ascend' : 'descend') : undefined);
                    setSelectStatus(value);
                    setCurrentPage(0);
                }}

                totalRecords={listApps?.data?.pagination?.totalRows ?? 0}
            />
            <TableApps
                dataSource={listApps?.data.hits}
                isLoading={loading}
                sortOrder={sortOrder}
                pagination={listApps?.data.pagination}
                onCurrentPage={(e: number) => onHandleChangePage(e)}
                onRemove={onHandleRemoveApp}
                currentPage={currentPage}
                disableStatus={true}
                onFilter={({ page, sorter, pageSize }) => {
                    setSortOrder(!!sorter.created_at ? (sorter.created_at === 'asc' ? 'ascend' : 'descend') : undefined);
                    run({
                        page,
                        sorter,
                        searcher: {
                            app_id: { value: searchValue.trim(), operator: 'contains' },
                            is_owner: { value: selectStatus == 'ALL' ? undefined : 'true', operator: onChangeStatusOperator(selectStatus) },
                        },
                        pageSize,
                        username: param.username
                    });
                }}
                onChangeStatus={() => message.error("To Update! You must be owner!")} />
        </div>
    );
};
