import { Space, Spin } from 'antd';
import { Props } from './types';
import { Overlay } from '../Overlay';

export const Loading: React.FC<Props> = (props) => {
  return (
    <>
      {props.isOverlay === false ? (
        <Spin size={props.size ?? 'large'} />
      ) : (
        <Overlay {...props} open={typeof props.open === 'boolean' ? props.open : false}>
          <Spin size={props.size ?? 'large'} />
        </Overlay>
      )}
    </>
  );
};
