import { RECORDS_PER_PAGE } from '@constants';
import api from '@services/api';
import { ParamsSettings, PartnerConnectorDocument, ResponseDetailSuccess, ResponseSuccess, RoleEnum } from '@types';
import { getSearchParams, getSortParams } from '@utils/helper';
import { useRequest } from 'ahooks';
import { AddKeywordParams, AdminPartnerKeywordDocument, CopyKeywordParams } from 'src/types/admin-keyword';

const userData = api.getAuthenticated()

export const getListKeywords = async ({
  page,
  sorter,
  searcher,
  pageSize,
}: ParamsSettings<AdminPartnerKeywordDocument>): Promise<ResponseSuccess<AdminPartnerKeywordDocument>> => {
  const size = pageSize ? pageSize : RECORDS_PER_PAGE;
  const response: ResponseSuccess<AdminPartnerKeywordDocument> = await api.getListDocument({
    url: '/admin/partner/keywords',
    params: {
      limit: size,
      offset: page * size,
      ...getSortParams({ created_at: 'desc', ...sorter }, false),
      ...getSearchParams(searcher),
    },
  });
  return response;
};

export const getAllKeywords = async ({
  page,
  sorter,
  searcher,
  pageSize,
}: ParamsSettings<AdminPartnerKeywordDocument>): Promise<ResponseSuccess<AdminPartnerKeywordDocument>> => {
  const size = pageSize ? pageSize : RECORDS_PER_PAGE;
  const response: ResponseSuccess<AdminPartnerKeywordDocument> = await api.getListDocument({
    url: '/admin/root/keywords',
    params: {
      limit: size,
      offset: page * size,
      ...getSortParams({ created_at: 'desc', ...sorter }, false),
      ...getSearchParams(searcher),
    },
  });
  return response;
};

export const useGetListKeywords = () => {
  return useRequest<ResponseSuccess<AdminPartnerKeywordDocument>, [ParamsSettings<AdminPartnerKeywordDocument>]>(
    userData.role === RoleEnum.ROOT ? getAllKeywords : getListKeywords,
    { manual: true },
  );
};

export const rootGetListKeywords = async ({
  page,
  sorter,
  searcher,
  pageSize,
  username,
}: any): Promise<ResponseSuccess<AdminPartnerKeywordDocument>> => {
  const size = pageSize ? pageSize : RECORDS_PER_PAGE;
  const response: ResponseSuccess<AdminPartnerKeywordDocument> = await api.getListDocument({
    url: `/admin/root/${username}/keywords`,
    params: {
      limit: size,
      offset: page * size,
      ...getSortParams({ created_at: 'desc', ...sorter }, false),
      ...getSearchParams(searcher),
    },
  });
  return response;
};

export const partnerGetListKeywordsOfApp = async ({
  page,
  sorter,
  searcher,
  pageSize,
  appId,
}: any): Promise<ResponseSuccess<AdminPartnerKeywordDocument>> => {
  const size = pageSize ? pageSize : RECORDS_PER_PAGE;
  const response: ResponseSuccess<AdminPartnerKeywordDocument> = await api.getListDocument({
    url: `/admin/partner/${appId}/keyword`,
    params: {
      limit: size,
      offset: page * size,
      ...getSortParams({ created_at: 'desc', ...sorter }, false),
      ...getSearchParams(searcher),
    },
  });
  return response;
};

export const useGetListKeywordsOfApp = () => {
  return useRequest<ResponseSuccess<AdminPartnerKeywordDocument>, [any]>( partnerGetListKeywordsOfApp, { manual: true });
};

export const rootGetListKeywordsOfApp = async ({
  page,
  sorter,
  searcher,
  pageSize,
  appId,
  username
}: any): Promise<ResponseSuccess<AdminPartnerKeywordDocument>> => {
  const size = pageSize ? pageSize : RECORDS_PER_PAGE;
  const response: ResponseSuccess<AdminPartnerKeywordDocument> = await api.getListDocument({
    url: `/admin/partner/${username}/${appId}/keyword`,
    params: {
      limit: size,
      offset: page * size,
      ...getSortParams({ created_at: 'desc', ...sorter }, false),
      ...getSearchParams(searcher),
    },
  });
  return response;
};

export const adminGetListKeywordsOfApp = () => {
  return useRequest<ResponseSuccess<AdminPartnerKeywordDocument>, [any]>( rootGetListKeywordsOfApp, { manual: true });
};

export const useRootGetListKeywords = () => {
  return useRequest<ResponseSuccess<AdminPartnerKeywordDocument>, [any]>(rootGetListKeywords, { manual: true });
};

export const createKeywordForPartner = async (data: AddKeywordParams): Promise<ResponseDetailSuccess<any>> => {
  const response: ResponseDetailSuccess<any> = await api.postDocument({
    url: `/admin/partner/keywords`,
    data: data,
  });

  return response as ResponseDetailSuccess<any>;
};

export const copyKeywordForPartner = async (data: CopyKeywordParams): Promise<ResponseDetailSuccess<any>> => {
  const response: ResponseDetailSuccess<any> = await api.postDocument({
    url: `/admin/partner/keywords/copy`,
    data: data,
  });

  return response as ResponseDetailSuccess<any>;
};

export const deleteKeywordForPartner = async (data: AddKeywordParams): Promise<ResponseDetailSuccess<any>> => {
  const response: ResponseDetailSuccess<any> = await api.postDocument({
    url: `/admin/partner/keywords/delete`,
    data: data,
  });

  return response as ResponseDetailSuccess<any>;
};
