import { useRootGetListKeywords } from '@services/manage-keyword/manage-keyword';
import { PageProps } from '@types';
import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FilterKeyword } from './components/FilterKeyword';
import { TableKeyword } from './components/TableKeyword';
import './styles.scss';
import { useQuery } from '../../hooks/useQuery';
import { SVGIcon } from '@components';
import { deleteKeywordByRootforPartner } from '@services/manage-app/manage-app';

export const PartnerKeyword: React.FC<PageProps> = () => {
    const { data: listPartnerKeywords, loading, run, refresh } = useRootGetListKeywords();
    const param = useParams<{ username: string }>()
    const [searchValue, setSearchValue] = useState<string>('');
    const [sortOrder, setSortOrder] = useState<'ascend' | 'descend' | undefined>();
    const [currentPg, setCurrentPg] = useState<number>(0)
    const history = useHistory()
    const query = useQuery()

    useEffect(() => {
        const loadPage = query?.get('page')
        let page = 0
        if (!loadPage) page = 1
        else page = +loadPage

        history.push('?page=' + page)
        setCurrentPg(page - 1)
        run({
            page: page - 1,
            searcher: {},
            sorter: {
                created_at: sortOrder === 'ascend' ? 'asc' : sortOrder === 'descend' ? 'desc' : undefined,
            },
            username: param.username
        });
    }, []);

    const onHandleChangePage = (e: number) => {
        setCurrentPg(e)
        const _e = e + 1
        history.push('?page=' + _e)
    }

    const onHandleRemoveApp = async ({ app_id, keyword }: { app_id: string; keyword: string }, currentPg: number, hash_user?: string,) => {
        const response = await deleteKeywordByRootforPartner(app_id, hash_user, keyword);
        console.log("RESPONSE", response)
        if (response.code == 0) {
          message.success('Remove success!');
          run({
            page: currentPg,
            searcher: {
                app_id: { value: searchValue, operator: 'contains' },
            },
            sorter: {
                created_at: sortOrder === 'ascend' ? 'asc' : sortOrder === 'descend' ? 'desc' : undefined,
            },
            username: param.username
          });
        } else {
            message.error('Remove error!')
        }
    }

    return (
        <div style={{ padding: '24px' }}>
            <h2 style={{ display: 'flex', alignItems: 'center' }}>
                <span onClick={() => history.push('/admin/partners?page=1')} style={{ cursor: 'pointer' }}>
                    <SVGIcon name="back" width={30} height={30} style={{ marginTop: 4 }} />
                </span>
                {param.username} partner keyword list
            </h2>
            <FilterKeyword
                totalRecords={listPartnerKeywords?.data?.pagination?.totalRows ?? 0}
                onSearch={(value) => {
                    run({
                        page: 0,
                        searcher: {
                            app_id: { value: value ?? undefined, operator: 'contains' },
                        },
                        sorter: {
                            created_at: sortOrder === 'ascend' ? 'asc' : sortOrder === 'descend' ? 'desc' : undefined,
                        },
                        username: param.username
                    });
                    setSearchValue(value);
                    setCurrentPg(0)
                }}
                onOpen={() => message.error("To Add! You must be owner!")} />
            <TableKeyword
                onPageChange={run}
                dataSource={listPartnerKeywords?.data?.hits ?? []}
                isLoading={loading}
                onCurrentPage={(e) => onHandleChangePage(e)}
                onRemove={onHandleRemoveApp}
                currentPage={currentPg}
                sortOrder={sortOrder}
                pagination={listPartnerKeywords?.data.pagination}
                onFilter={({ page, sorter, pageSize }) => {
                    setSortOrder(!!sorter.created_at ? (sorter.created_at === 'asc' ? 'ascend' : 'descend') : undefined);
                    run({
                        page,
                        sorter,
                        searcher: {
                            app_id: { value: searchValue, operator: 'contains' },
                        },
                        pageSize,
                        username: param.username
                    });
                }}
                isDisabled 
            />
        </div>
    );
};
