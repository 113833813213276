import { FeatureEnum } from './admin-subscription';
import { RecordStatus } from './base';

export enum RoleEnum {
  ROOT = 'ROOT',
  PARTNER_ADMIN = 'PARTNER_ADMIN',
  PARTNER_ADMIN_MEMBER = 'PARTNER_ADMIN_MEMBER',
}

export interface PartnerConnectorDocument {
  _id: string;
  fullname: string;
  google_token: {
    client_id: string;
    client_secret: string;
    refresh_token: string;
    scopes: Array<string>;
    token: string;
    token_uri: string;
  };
  currentSubscription: {
    subscriptionType: SubscriptionEnum;
  };
  password: string;
  hash_user?: string;
  active_code?: string;
  totalApps: number;
  totalKeywords: number;
  username: string;
  inactive: boolean;
  created_at: string;
  updated_at: string;
  createdAt: string;
  last_login_at: string;
}

export interface UserLoginParams {
  username: string;
  password: string;
}

export interface AdminPartnerDocumentType {
  _id: string;
  partnerCode: string;
  partnerAccount: string;
  username: string;
  password: string;
  role: RoleEnum;
  status: RecordStatus;
  createdAt?: string;
  updatedAt?: string;
}
export interface ListAppPartnerMemberDocumentType {
  _id: string;
  app_id: string;
  created_at?: string;
  updated_at?: string;
  ga_connected?: boolean;
  shopify_connected?: boolean;
}
export interface ListAppPartnerAdminDocumentType {
  _id: string;
  app_id: string;
  ga_connected?: boolean;
  shopify_connected?: boolean;
}

export interface AppsForPartnerMember {
  _id: string;
  app_id: string;
  created_at?: string;
  hash_user: string;
  updated_at?: string;
  is_owner?: boolean;
  username: string;
  watched_changes?: boolean;
}

export type AddAppParams = {
  username: string;
  appId: string;
};

export interface AccessPartnerMember {
  _id: string;
  app_id: string;
  created_at?: string;
  hash_user: string;
  updated_at?: string;
  is_owner?: boolean;
  username: string;
  watched_changes?: boolean;
  ga_connected?: boolean;
  shopify_connected?: boolean;
}

export type CheckAccountPartnerMemberResponse = {
  code: number;
  data: {
    can_add: boolean;
    code: number;
    message?: string | null;
  };
};

export type CreateAccountPartnerMemberResponse = {
  code: number;
  message?: string | null;
  send_email: boolean;
};

export enum SubscriptionEnum {
  TRIAL = 'TRIAL',
  STANDARD = 'STANDARD',
  PRO = 'PRO',
  UNLIMITED = 'UNLIMITED',
  EXPIRED = 'EXPIRED',
}

export enum ConnectTypeEnum {
  SHOPIFY = 'shopify',
  GA = 'ga',
}

export type UpdateProductPlanParam = {
  name: string;
  monthlyPrice: number;
  trialValue: number;
  appValue: number;
  keywordValue: number;
};

//- Dữ liệu gửi lên server
export type UpdateProductPlanRequestParam = {
  name: string;
  monthlyPrice: number;
  features: Array<{ type: FeatureEnum; value: string | number }>;
};

export type CreateAdminMemberParam = {
  username: string;
};
