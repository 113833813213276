import { adminGetListKeywordsOfApp } from '@services/manage-keyword/manage-keyword';
import { PageProps, RoleEnum } from '@types';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TableKeyword } from './components/TableKeyword';
import './styles.scss';
import { message } from 'antd';
import { useQuery } from '../../hooks/useQuery';
import { SVGIcon } from '@components';
import { deleteKeywordByRootforPartner } from '@services/manage-app/manage-app';
import api from '@services/api';

export const AdminPartnerAppKeyword: React.FC<PageProps> = () => {
    const { data: listPartnerKeywords, loading, run, refresh } = adminGetListKeywordsOfApp();
    const param = useParams<{ username: string, appId: string }>()
    const [sortOrder, setSortOrder] = useState<'ascend' | 'descend' | undefined>();
    const [currentPg, setCurrentPg] = useState<number>(0)
    const history = useHistory()
    const query = useQuery()
    const userData = api.getAuthenticated();

    useEffect(() => {
        const loadPage = query?.get('page')
        let page = 0
        if (!loadPage) page = 1
        else page = +loadPage

        history.push('?page=' + page)
        setCurrentPg(page - 1)
        run({
            page: page - 1,
            searcher: {},
            sorter: {},
            appId: param.appId,
            username: param.username
        });
    }, []);

    const onHandleChangePage = (e: number) => {
        setCurrentPg(e)
        const _e = e + 1
        history.push('?page=' + _e)
    }

    const onHandleRemoveApp = async ({ app_id, keyword }: { app_id: string; keyword: string }, currentPg: number, hash_user?: string) => {
        const response = await deleteKeywordByRootforPartner(app_id, hash_user, keyword);
        console.log("RESPONSE", response)
        if (response.code == 0) {
          message.success('Remove success!');
          run({
            page: currentPg,
            searcher: {},
            sorter: {},
            appId: param.appId,
            username: param.username
          });
        } else {
            message.error('Remove error!')
        }
    }

    return (
        <div style={{ padding: '24px' }}>
            <h2 style={{ display: 'flex', alignItems: 'center' }}>
                <span onClick={() => history.push( userData.role == RoleEnum.ROOT ? `/admin/partner/${param.username}/apps?page=1` : `/admin/apps?page=1`)} style={{ cursor: 'pointer' }}>
                    <SVGIcon name="back" width={30} height={30} style={{ marginTop: 4 }} />
                </span>
                {userData.role === RoleEnum.ROOT ? `App ${param.appId} keywords added by username ${param.username}` : `${param.appId} app keywords`}
            </h2>
            <TableKeyword
                onPageChange={run}
                dataSource={listPartnerKeywords?.data?.hits ?? []}
                isLoading={loading}
                onCurrentPage={(e) => onHandleChangePage(e)}
                onRemove={onHandleRemoveApp}
                currentPage={currentPg}
                sortOrder={sortOrder}
                pagination={listPartnerKeywords?.data.pagination}
                onFilter={({ page, sorter, pageSize }) => {
                    setSortOrder(!!sorter.title ? (sorter.title === 'asc' ? 'ascend' : 'descend') : undefined);
                    run({
                        page,
                        sorter,
                        pageSize,
                        searcher: {},
                        appId: param.appId,
                        username: param.username
                    });
                }}
                isDisabled 
            />
        </div>
    );
};
