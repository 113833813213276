const ink = {
  100: '#0F1824',
  90: '#46515F',
  80: '#444B55',
  70: '#5F656D',
  60: '#83878D',
  50: '#94989E',
  40: '#A6A9AE',
  30: '#B8BBBE',
  20: '#CACCCE',
  10: '#DBDDDF',
  5: '#EDEEEF',
  0: '#FFF',
};

const primary = {
  110: '#007DEB',
  100: '#0088FF',
  80: '#2097FF',
  60: '#40A6FF',
  50: '#60B5FF',
  40: '#80C3FF',
  20: '#9FD2FF',
  10: '#BFE1FF',
  5: '#DFF0FF',
  gradient: {
    from: '#0088FF',
    to: '#0071D4',
  },
};

const secondary = {
  110: '#0EC67F',
  100: '#0FD186',
  80: '#3FDA9E',
  60: '#6FE3B6',
  50: '#9FEDCF',
  40: '#CFF6E7',
  20: '#DBF8ED',
  10: '#E6F8F1',
  5: '#EDFCF7',
  gradient: {
    from: '#0FD186',
    to: '#3FDA9E',
  },
};

const yellow = {
  110: '#EDA000',
  100: '#FFAE06',
  80: '#FFBE38',
  60: '#FFCE6A',
  50: '#FFDF9B',
  40: '#FFEFCD',
  20: '#FFF3DA',
  10: '#FFF7E7',
  5: '#FFFBF2',
  gradient: {
    from: '#FFAE06',
    to: '#FFBE38',
  },
};

const red = {
  110: '#E83232',
  100: '#EB4444',
  80: '#ED5B5B',
  60: '#F07373',
  50: '#F38A8A',
  40: '#F5A2A2',
  20: '#F8B9B9',
  10: '#FAD0D0',
  5: '#FDE8E8',
  gradient: {
    from: '#F02727',
    to: '#CB2626',
  },
};

const text = {
  100: '#2A323C',
  90: '#46515F',
  70: '#636C77',
  50: '#94989E',
  10: '#f5f5f5',
  0: '#FFFFFF',
};

const background = {
  white: '#FFF',
  main: '#F4F5F5',
  blur: '#F4F5F5',
};

const icon = {
  primary: '#46515F',
  secondary: '#A3A8AF',
};

const notifyText = {
  danger: '#EE4747',
  warning: '#E49C06',
  success: '#0DB473',
  info: '#5364FE',
};

const green = {
  110: '#00A877',
  100: '#00C381',
  80: '#00D48E',
  60: '#00E29C',
  50: '#00F0A9',
  40: '#32FFBB',
  20: '#65FFCD',
  10: '#97FFDE',
  5: '#C9FFEF',
  gradient: {
    from: '#00C381',
    to: '#00D48E',
  },
};

const orange = {
  110: '#FF8700',
  100: '#FF9800',
  80: '#FFA922',
  60: '#FFBB44',
  50: '#FFC966',
  40: '#FFDD88',
  20: '#FFE499',
  10: '#FFEEBB',
  5: '#FFF3DD',
  gradient: {
    from: '#FF9800',
    to: '#FFA922',
  },
};

const darkOrange = {
  110: '#D97E39',
  100: '#E28945',
  80: '#E99551',
  60: '#F3A05E',
  50: '#F8AC6A',
  40: '#FDB877',
  20: '#FFC483',
  10: '#FFD090',
  5: '#FFF4EC',
  gradient: {
    from: '#E28945',
    to: '#E99551',
  },
};

const colors = {
  ink,
  primary,
  secondary,
  yellow,
  red,
  text,
  background,
  icon,
  notifyText,
  green,
  orange,
  darkOrange,
};

export default colors;
