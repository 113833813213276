import { SVGIcon } from '@components';
import { AdminPartnerDocumentType } from '@types';
import { Layout, Menu, SiderProps } from 'antd';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SIDEBAR_ITEMS } from './sidebar.constant';
import './styles.scss';
import { SidebarItemTypes } from './types';

const { Sider } = Layout;
type SidebarProps = {
  userData: AdminPartnerDocumentType;
} & SiderProps;

export const Sidebar: React.FC<SidebarProps> = (props) => {
  const location = useLocation();
  const items2 = SIDEBAR_ITEMS.map((item: SidebarItemTypes) => {
    const icon = <SVGIcon name={item.icon} />;
    return {
      key: item.path,
      icon,
      label: item.label,
      path: item.path,
      isShow: item.roles.includes(props.userData.role) ?? false,
    };
  });

  return (
    <div className="side-menu-container">
      <Sider width={300} breakpoint={'lg'} collapsedWidth={0} trigger={null}>
        <Menu mode="inline" theme="light" selectedKeys={[location.pathname]} style={{ height: '100%'}}>
          {items2.map((item) => {
            if (!item.isShow) return null;
            return (
              <Menu.Item key={item.key} className="side-menu_item">
                {item.icon}
                <span>{item.label}</span>
                <Link to={item.path} />
              </Menu.Item>
            );
          })}
        </Menu>
      </Sider>
    </div>
  );
};
