import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';

import { ConfigProvider } from 'antd';
import { AppRouter } from './routers';
import * as serviceWorker from './serviceWorker';
import { store } from './store';

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider>
      <AppRouter />
    </ConfigProvider>
  </Provider>,
  document.getElementById('root'),
);
serviceWorker.unregister();
