import { ActionItem, ActionTable, AntTable, SVGIcon } from '@components';
import { ParamsSettings } from '@types';
import { getPaginationFromAntdTable, getSorterParamsFromAntdTable } from '@utils/helper';
import { Popconfirm, Switch, Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '../../../hooks/useQuery';
import { AppUserDocument } from 'src/types/admin-keyword';
import colors from '@utils/colors';
interface Props {
  dataSource?: any[];
  isLoading?: boolean;
  pagination?: {
    totalRows?: number;
  };
  sortOrder?: 'ascend' | 'descend';
  currentPage: number;
  disableStatus?: boolean;
  onFilter?: (params: ParamsSettings<any>) => void;
  onRefresh?: () => void;
  onRemove?: (_id: string, currentPage: number, hash_user?: string) => void;
  onChangeStatus?: (e: boolean, app_id: string, currentPage: number) => void;
  onCurrentPage: (e: number) => void;
  isShowGAStatus?: boolean;
}

const { Text } = Typography;

export const TableApps = ({
  dataSource = [],
  isLoading,
  pagination,
  currentPage,
  disableStatus = false,
  onFilter,
  onChangeStatus,
  onRemove,
  onCurrentPage,
  isShowGAStatus,
}: Props) => {
  const query = useQuery();
  const pageValue = query?.get('page');
  const searchValue = query?.get('search');
  const [open, setOpen] = useState<boolean>(false);
  const [rowSelected, setRowSelected] = useState<any>();
  const [pageSize, setPageSize] = useState<number>(10);
  const [openDelete, setOpenDelete] = useState(false);

  const handleClickOpen = (row: AppUserDocument) => () => {
    console.log(row);
  };

  const columns: ColumnsType<AppUserDocument> = [
    {
      key: 'app_id',
      dataIndex: 'app_id',
      title: 'Apps',
      render: (value: string, item) => (
        <Text>
          <a className="app-link" href={`https://letsmetrix.com/app/${item.app_id}`} target="_blank">
            {isShowGAStatus && item.detail ? item.detail.name : item.app_id}
          </a>
        </Text>
      ),
      width: 250,
    },
    {
      key: 'created_at',
      dataIndex: 'created_at',
      title: 'Created At',
      render: (value: string, item) => <Text onClick={handleClickOpen(item)}>{value}</Text>,
      sorter: (a, b) => {
        const aValue = a.created_at || '';
        const bValue = b.created_at || '';
        return aValue.length - bValue.length;
      },
      width: 150,
      sortDirections: ['descend', 'ascend'],
    },
    {
      key: 'updated_at',
      dataIndex: 'updated_at',
      title: 'Last Updated At',
      render: (value: string) => <Text>{value ?? '-'}</Text>,
      width: 150,
    },
    {
      key: 'number_of_keyword',
      dataIndex: 'number_of_keyword',
      title: 'Keywords',
      render: (value: string, item) =>
        value ? (
          <Link
            className="app-link"
            to={`/admin/users-keywords/${item.app_id}/apps?old-page=${pageValue}${
              searchValue ? `&search=${searchValue}` : ''
            }`}
          >
            <Text style={{ color: colors.primary[100], textDecoration: 'underline', fontWeight: 550 }}>{value}</Text>
          </Link>
        ) : (
          '-'
        ),
      sorter: (a, b) => a.number_of_keyword - b.number_of_keyword,
      width: 100,
      hidden: !isShowGAStatus,
      sortDirections: ['descend', 'ascend'],
    },
    {
      key: 'number_of_users_ga',
      dataIndex: 'number_of_users_ga',
      title: 'Users.GA',
      render: (value: string, item) =>
        value ? (
          <Link className="app-link" to={`/admin/users-connect-ga/${item.app_id}/apps`}>
            <Text style={{ color: colors.primary[100], textDecoration: 'underline', fontWeight: 550 }}>{value}</Text>
          </Link>
        ) : (
          '-'
        ),
      sorter: (a, b) => a.number_of_users_ga - b.number_of_users_ga,
      width: 100,
      hidden: !isShowGAStatus,
      sortDirections: ['descend', 'ascend'],
    },
    {
      key: 'number_of_users_shopify',
      dataIndex: 'number_of_users_shopify',
      title: 'Users.Shopify',
      render: (value: string, item) =>
        value ? (
          <Link className="app-link" to={`/admin/users-connect-shopify/${item.app_id}/apps`}>
            <Text style={{ color: colors.primary[100], textDecoration: 'underline', fontWeight: 550 }}>{value}</Text>
          </Link>
        ) : (
          '-'
        ),
      sorter: (a, b) => a.number_of_users_shopify - b.number_of_users_shopify,
      width: 100,
      hidden: !isShowGAStatus,
      sortDirections: ['descend', 'ascend'],
    },
    {
      key: 'categories',
      dataIndex: 'categories',
      title: 'Category',
      render: (value: string, item) => (
        <ul className="list-category">
          {item.categories && item.categories.length !== 0 ? (
            item.categories.map((item, index) => (
              <li key={index}>
                <Tag color={colors.secondary[110]}>
                  <Text style={{ color: colors.text[0] }}>{item.rank}</Text>
                </Tag>
                <Text>{item.category_name ? item.category_name : '-'}</Text>
              </li>
            ))
          ) : (
            <>-</>
          )}
        </ul>
      ),
      width: 300,
      hidden: !isShowGAStatus,
    },
    {
      key: 'collections',
      dataIndex: 'collections',
      title: 'Collections',
      render: (value: string, item) => (
        <ul className="list-category">
          {item.collections && item.collections.length !== 0 ? (
            item.collections.map((item, index) => (
              <li key={index}>
                <Tag color={colors.secondary[110]}>
                  <Text style={{ color: colors.text[0] }}>{item.rank}</Text>
                </Tag>
                <Text>{item.collection_name ? item.collection_name : '-'}</Text>
              </li>
            ))
          ) : (
            <>-</>
          )}
        </ul>
      ),
      width: 300,
      hidden: !isShowGAStatus,
    },
    {
      key: 'totalKeywords',
      dataIndex: 'totalKeywords',
      title: 'Keywords',
      render: (value: string, item) => (
        <Link to={`/admin/partner/app/${item.username}/${item.app_id}/keyword`}>
          <h3>{value}</h3>
        </Link>
      ),
      width: 200,
    },
    {
      key: 'ga_connected',
      dataIndex: 'ga_connected',
      title: 'Ga Connected',
      render: (value: string, item: any) => {
        return <Switch disabled={true} defaultChecked={item.ga_connected}></Switch>;
      },
      width: 200,
      hidden: isShowGAStatus,
    },
    {
      key: 'shopify_connected',
      dataIndex: 'shopify_connected',
      title: 'Shopify Connected',
      render: (value: string, item: any) => {
        return <Switch disabled={true} defaultChecked={item.shopify_connected}></Switch>;
      },
      width: 200,
      hidden: isShowGAStatus,
    },
    {
      key: 'is_owner',
      dataIndex: 'is_owner',
      title: 'My Apps',
      render: (value: string, item: any) => {
        return <Switch disabled={true} defaultChecked={item.is_owner}></Switch>;
      },
      width: 200,
      hidden: isShowGAStatus,
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: 'Status',
      render: (value: string, item: any) => {
        return <Switch disabled={true} defaultChecked={!item.unlisted && !item.delete}></Switch>;
      },
      width: 200,
    },
    {
      key: 'action',
      title: 'Action',
      render: (_, row) => {
        const deleteActions: ActionItem<AppUserDocument> = {
          name: 'table_delete',
          onClickAction: (row: AppUserDocument) => {},
          width: 18,
          height: 18,
        };
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {onRemove ? (
              <Popconfirm
                title="Are you sure?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => onRemove(row.app_id, currentPage, row?.hash_user)}
              >
                <div className="remove">
                  <ActionTable action={deleteActions} row={row} />
                </div>
              </Popconfirm>
            ) : (
              <SVGIcon name="table_delete" />
            )}
          </div>
        );
      },
      align: 'center',
      width: 150,
      hidden: isShowGAStatus,
    },
  ];

  return (
    <div className="apps-table">
      <AntTable
        columns={columns}
        dataSource={dataSource}
        rowKey={(r) => r._id}
        loading={isLoading}
        pagination={{
          pageSize: pageSize,
          total: pagination?.totalRows,
          current: currentPage + 1,
          pageSizeOptions: [10],
        }}
        onChange={(pagination, _, sorter, extra) => {
          onCurrentPage(getPaginationFromAntdTable({ pagination, extra }));
          onFilter?.({
            page: getPaginationFromAntdTable({ pagination, extra }),
            sorter: getSorterParamsFromAntdTable({ sorter }),
            searcher: {},
            pageSize: pagination.pageSize,
          });
          setPageSize(pagination.pageSize ?? pageSize);
        }}
      />
    </div>
  );
};
