import { SubscriptionEnum } from "@types";
import colors from "./colors";

export const subscriptionMap = {
    [SubscriptionEnum.TRIAL]: {
      title: 'Free',
      color: colors.secondary[110],
      bg: colors.secondary[5],
    },
    [SubscriptionEnum.STANDARD]: {
      title: 'Standard',
      color: colors.primary[110],
      bg: colors.primary[5],
    },
    [SubscriptionEnum.PRO]: {
      title: 'Pro',
      color: colors.darkOrange[110],
      bg: colors.darkOrange[5],
    },
    [SubscriptionEnum.UNLIMITED]: {
      title: 'Unlimited',
      color: colors.yellow[110],
      bg: colors.yellow[5],
    },
    [SubscriptionEnum.EXPIRED]: {
      title: 'Expired',
      color: colors.red[110],
      bg: colors.red[5],
    },
  };