import { message as messageAPI } from 'antd';
import { ERROR_CODE } from '@constants';
import { ErrorBase } from '@errors';

export const errorHandle = async (unknowError: ErrorBase) => {
  const error: ErrorBase = unknowError as ErrorBase;
  if (error.code == ERROR_CODE.HTTP_SUCCESS) {
    messageAPI.success('Execute success!');
  }
};
