import { PageProps } from '@types';
import {  useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import './styles.scss';
import { useQuery } from '../../hooks/useQuery';
import { SVGIcon } from '@components';
import { useGetUserConnectPartner } from '@services/manage-app/manage-app';
import { useMount } from 'ahooks';
import { TableUserConnect } from './components/TableUserConnect';


export const UserPartnerConnect: React.FC<PageProps> = () => {
    const { data: listUser, loading, run, refresh } = useGetUserConnectPartner();
    const param = useParams<{ app: string }>()
    const [currentPg, setCurrentPg] = useState<number>(0)
    const history = useHistory()
    const query = useQuery()

    useMount(() => {
        const loadPage = query?.get('page')
        let page = 0
        if (!loadPage) page = 1
        else page = +loadPage
        history.push('?page=' + page)
        setCurrentPg(page - 1)
        run({
            page: page -1 ,
            page_size: 10,
            app_id: param.app,
        });
    });

    const onHandleChangePage = (e: number) => {
        setCurrentPg(e)
        const _e = e + 1
        history.push('?page=' + _e)
    }
    
    return (
        <div style={{ padding: '24px' }}>
            <h2 style={{ display: 'flex', alignItems: 'center' }}>
                <span onClick={() => history.push('/admin/apps?page=1')} style={{ cursor: 'pointer' }}>
                    <SVGIcon name="back" width={30} height={30} style={{ marginTop: 4 }} />
                </span>
                User Connect to Shopify
            </h2>
            <div className="filter-container_transaction">
                <div>
                    <span>Total Records:</span> <span>{listUser?.data?.pagination?.totalRows ?? 0}</span>
                </div>
            </div>
            <TableUserConnect
                onPageChange={run}
                dataSource={listUser?.data?.hits ?? []}
                isLoading={loading}
                onCurrentPage={(e) => onHandleChangePage(e)}
                currentPage={currentPg}
                pagination={listUser?.data.pagination}
                onFilter={({ page, sorter, pageSize }) => {
                    run({
                      page,
                      sorter,
                      pageSize,
                      app_id: param.app
                    });
                  }}
                 />
        </div>
    );
};
