import { PropsWithChildren, useEffect, useState } from 'react';
import { Header } from './Header';
import { Sidebar } from './Sidebar';
import './styles.scss';
import api from '@services/api';
import { RoleEnum } from '@types';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTERS_MAIN } from '@constants';
import { Navbar } from './Navbar/Navbar';

export const MainLayout: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const userData = api.getAuthenticated();
  const history = useHistory();
  const location = useLocation();
  const [isSize, setIsSize] = useState(false);
  const renderPath = () => {
    if (location.pathname == '/') {
      if (userData.role == RoleEnum.ROOT) {
        history.push(ROUTERS_MAIN.DASHBOARD);
      } else if (userData.role == RoleEnum.PARTNER_ADMIN) {
        history.push(ROUTERS_MAIN.USER);
      } else {
        history.push(ROUTERS_MAIN.APP);
      }
    }
  };

  useEffect(() => {
    renderPath();
    const handleResize = () => {
      setIsSize(window.innerWidth <= 1024);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [location]);

  return (
    <div className="main-layout-container">
      <Header username={userData.username} />
      <div className="main-layout">
        {isSize && <Navbar userData={userData} />}
        {!isSize && <Sidebar userData={userData} />}
        <div className="main-content">{children}</div>
      </div>
    </div>
  );
};
