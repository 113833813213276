import { RECORDS_PER_PAGE } from '@constants';
import api from '@services/api';
import {
  AccessPartnerMember,
  AdminPartnerDocumentType,
  AppsForPartnerMember,
  CheckAccountPartnerMemberResponse,
  ConnectTypeEnum,
  CreateAccountPartnerMemberResponse,
  ListAppPartnerAdminDocumentType,
  ListAppPartnerMemberDocumentType,
  ParamsSettings,
  RecordStatus,
  ResponseDetailSuccess,
  ResponseSuccess,
} from '@types';
import { getSearchParams, getSortParams } from '@utils/helper';
import { useRequest } from 'ahooks';
import _ from 'lodash';
import { ProductPlanDocument } from 'src/types/admin-subscription';

export const getListAdminMembers = async ({
  page,
  sorter,
  searcher,
}: ParamsSettings<AdminPartnerDocumentType>): Promise<ResponseSuccess<AdminPartnerDocumentType>> => {
  const response: ResponseSuccess<AdminPartnerDocumentType> = await api.getListDocument({
    url: '/admin/get-partner-member',
    params: {
      limit: RECORDS_PER_PAGE,
      offset: page * RECORDS_PER_PAGE,
      ...getSortParams(sorter),
      ...getSearchParams(searcher),
    },
  });

  return response;
};

export const useGetListAdminMember = () => {
  return useRequest<ResponseSuccess<AdminPartnerDocumentType>, [ParamsSettings<ProductPlanDocument>]>(
    getListAdminMembers,
    {
      manual: true,
    },
  );
};

export const createAdminMember = async (data: { username: string }): Promise<CreateAccountPartnerMemberResponse> => {
  const response = await api.postDocument({
    url: `/admin/add-partner-member`,
    data: data,
  });

  return response.data as CreateAccountPartnerMemberResponse;
};

export const deleteAdminMember = async (data: {
  username: string;
}): Promise<ResponseDetailSuccess<AdminPartnerDocumentType>> => {
  const response: ResponseDetailSuccess<any> = await api.postDocument({
    url: `/admin/delete-partner-member`,
    data: data,
  });

  return response.data as ResponseDetailSuccess<AdminPartnerDocumentType>;
};

export const updatePartnerStatus = async (
  status: boolean,
  username: string,
): Promise<ResponseDetailSuccess<AdminPartnerDocumentType>> => {
  const response: ResponseDetailSuccess<any> = await api.postDocument({
    url: `/admin/root/partner/update-status`,
    data: { status, username },
  });

  return response.data as ResponseDetailSuccess<AdminPartnerDocumentType>;
};

export const removePartner = async (username: string): Promise<ResponseDetailSuccess<AdminPartnerDocumentType>> => {
  const response: ResponseDetailSuccess<any> = await api.postDocument({
    url: `/admin/root/partner/remove`,
    data: { username },
  });

  return response.data as ResponseDetailSuccess<AdminPartnerDocumentType>;
};

export const updateAdminMember = async (
  adminId: string,
  status: RecordStatus,
): Promise<ResponseDetailSuccess<AdminPartnerDocumentType>> => {
  const response: ResponseDetailSuccess<any> = await api.putDocument({
    url: `/admin/account/${adminId}/member`,
    data: { status },
  });

  return response.data as ResponseDetailSuccess<AdminPartnerDocumentType>;
};

export const listAppsPartnerMember = async (
  username: string,
): Promise<ResponseDetailSuccess<ListAppPartnerMemberDocumentType[]>> => {
  const response: ResponseDetailSuccess<any> = await api.postDocument({
    url: `admin/list-app-partner-member`,
    data: { username },
  });

  return response as ResponseDetailSuccess<ListAppPartnerMemberDocumentType[]>;
};

export const listAppsPartnerAdmin = async (): Promise<ResponseDetailSuccess<ListAppPartnerAdminDocumentType[]>> => {
  const response: ResponseDetailSuccess<any> = await api.getListDocument({
    url: `admin/list-app-partner-admin`,
  });

  return response as ResponseDetailSuccess<ListAppPartnerAdminDocumentType[]>;
};

export const addAppsForPartnerMember = async (
  username: string,
  appId: string,
): Promise<ResponseDetailSuccess<AppsForPartnerMember[]>> => {
  const response: ResponseDetailSuccess<any> = await api.postDocument({
    url: `admin/add-app-for-partner-member`,
    data: { username, appId },
  });

  return response as ResponseDetailSuccess<AppsForPartnerMember[]>;
};

export const setAccessPartnerMember = async (
  username: string,
  appId: string,
  connectType: ConnectTypeEnum,
  status: boolean,
): Promise<ResponseDetailSuccess<AccessPartnerMember[]>> => {
  const response: ResponseDetailSuccess<any> = await api.postDocument({
    url: `admin/set-access-partner-member`,
    data: { username, appId, connectType, status },
  });

  return response as ResponseDetailSuccess<AccessPartnerMember[]>;
};

export const checkAccountPartnerMember = async (username: string): Promise<CheckAccountPartnerMemberResponse> => {
  const response = await api.postDocument({
    url: `admin/check-before-add-partner-member`,
    data: { username },
  });

  return response as CheckAccountPartnerMemberResponse;
};
