import { SVGIcon } from '../SVGIcon';
import { ActionTableProps } from './types';
import './styles.scss';

export const ActionTable = <T extends object = any>({ action, row }: ActionTableProps<T>) => {
  return (
    <div className="action-table" onClick={() => action.onClickAction(row)}>
      <SVGIcon {...action} />
    </div>
  );
};
