import { SearchOutlined } from '@ant-design/icons';
import { useDebounce, useUpdateEffect } from 'ahooks';
import { Button, Input } from 'antd';
import { FC, useState } from 'react';

type Props = {
  onSearch: (e: string) => void;
  onOpen: () => void;
  totalRecords: number;
};
export const FilterTx: FC<Props> = ({ totalRecords, onSearch, onOpen }) => {
  const [searchKey, setSearchKey] = useState<string>('');
  const debouncedValue = useDebounce(searchKey, { wait: 500 });

  useUpdateEffect(() => {
    onSearch?.(debouncedValue);
  }, [debouncedValue]);

  return (
    <div className="filter-container_transaction">
      <div className="search_partner">
        <Input
          placeholder="Enter search partner"
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
          suffix={<SearchOutlined />}
          variant="outlined"
        />
      </div>
      <div>
        <span>Total Records:</span> <span>{totalRecords}</span>
      </div>
    </div>
  );
};
