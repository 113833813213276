import { SVGIcon } from '@components';
import { adminPartnerAddNewApps, deleteAppForPartner, useGetListAppNewest } from '@services/manage-app/manage-app';
import { PageProps } from '@types';
import { useMount } from 'ahooks';
import { message } from 'antd';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../hooks/useQuery';
import { FilterApps } from './components/FilterApps';
import { TableAppNewest } from './components/TableAppNewest';
import './styles.scss';

export const AddApp: React.FC<PageProps> = () => {
    const { data: listAppNewests, loading, run, refresh } = useGetListAppNewest();
    const [searchValue, setSearchValue] = useState<string>('');
    const [sortOrder, setSortOrder] = useState<'ascend' | 'descend' | undefined>();
    const [currentPage, setCurrentPage] = useState<number>(0);
    const history = useHistory();
    const query = useQuery();

    useMount(() => {
        const loadPage = query?.get('page');
        let page = 0;
        if (!loadPage) page = 1;
        else page = +loadPage;

        history.push('?page=' + page);
        setCurrentPage(page - 1);
        run({
            page: page - 1,
            searcher: {},
            sorter: {},
        });
    });

    const onAddApp = async (app_id: string) => {
        console.log('[onAddApp] ON', status);
        const response = await adminPartnerAddNewApps(app_id, true);

        console.log('onAddApp', response);
        if (response.code == 0) {
            message.success('Add success!');
            run({
                page: currentPage,
                searcher: {
                    app_id: { value: searchValue ?? undefined, operator: 'contains' },
                },
                sorter: {},
            });
        } else {
            message.error('Add error!');
        }
    };

    const onHandleRemoveApp = async (appId: string) => {
        console.log('[onHandleRemoveApp] ON', appId);
        const response = await deleteAppForPartner(appId);
        console.log('RESPONSE', response);
        if (response.code == 0) {
            message.success('Remove success!');
            run({
                page: currentPage,
                searcher: {
                    app_id: { value: searchValue ?? undefined, operator: 'contains' },
                },
                sorter: {},
            });
        } else {
            message.error('Remove error!');
        }
    };

    const onHandleChangePage = (e: number) => {
        setCurrentPage(e);
        const _e = e + 1;
        history.push('?page=' + _e);
    };

    return (
        <div style={{ padding: '24px' }}>
            <h2 style={{ display: 'flex', alignItems: 'center' }}>
                <span onClick={() => history.push('/admin/apps?page=1')} style={{ cursor: 'pointer' }}>
                    <SVGIcon name="back" width={30} height={30} style={{ marginTop: 4 }} />
                </span>
                Add New App
            </h2>
            <FilterApps
                onSearch={(value) => {
                    run({
                        page: 0,
                        searcher: {
                            app_id: { value, operator: 'contains' },
                        },
                        sorter: {},
                    });
                    setSearchValue(value);
                    setSortOrder(undefined);
                    setCurrentPage(0);
                }}
                totalRecords={listAppNewests?.data?.pagination?.totalRows ?? 0}
            />
            <TableAppNewest
                onRemove={onHandleRemoveApp}
                onAdd={onAddApp}
                dataSource={listAppNewests?.data.hits}
                isLoading={loading}
                sortOrder={sortOrder}
                pagination={listAppNewests?.data.pagination}
                onCurrentPage={(e: number) => onHandleChangePage(e)}
                currentPage={currentPage}
                onFilter={({ page, sorter, pageSize }) => {
                    setSortOrder(!!sorter.created_at ? (sorter.created_at === 'asc' ? 'ascend' : 'descend') : undefined);
                    run({
                        page,
                        sorter,
                        searcher: {
                            app_id: { value: searchValue.trim(), operator: 'contains' },
                        },
                        pageSize,
                    });
                }}
                onChangeStatus={() => message.error('To Update! You must be owner!')}
            />
        </div>
    );
};
