import { createSlice } from '@reduxjs/toolkit';

import api from '@services/api';
import { AdminPartnerDocumentType } from '@types';
import { userLogin, userProfile } from './thunk';

type State = {
  data: AdminPartnerDocumentType | undefined;
  isLogin: boolean;
};

// > State
const initialState: State = {
  data: undefined,
  isLogin: false,
};

// > Slice
const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      state.data = undefined;
      api.removeToken();
    },
  },
  extraReducers: (builder) => {
    // - Login
    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLogin = false;
    });
    builder.addCase(userLogin.pending, (state) => {
      state.data = undefined;
      state.isLogin = true;
    });
    builder.addCase(userLogin.rejected, (state) => {
      state.data = undefined;
      state.isLogin = false;
    });

    // - fetchProfile
    builder.addCase(userProfile.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLogin = false;
    });
    builder.addCase(userProfile.pending, (state) => {
      state.data = undefined;
      state.isLogin = true;
    });
    builder.addCase(userProfile.rejected, (state) => {
      state.data = undefined;
      state.isLogin = false;
    });
  },
});

// > Export
// * Action
export const actions = { ...slice.actions, userLogin, userProfile };

// * Reducer
export const { reducer } = slice;
