import { useGetListTransaction } from '@services/manage-tx/manage-tx';
import { PageProps } from '@types';
import { message } from 'antd';
import { useEffect, useState } from 'react';
import { FilterTx } from './components/FilterTx';
import { TableTx } from './components/TableTx';
import './styles.scss';
import { useGetListProductPlans } from '@services/manage-subscription/manage-subscription';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../hooks/useQuery';

export const PartnerTx: React.FC<PageProps> = () => {
    const { data: listTx, loading, run, refresh } = useGetListTransaction();
    const { data: listProductPlans, run: getProductPlan } = useGetListProductPlans();
    const [searchValue, setSearchValue] = useState<string>('');
    const [sortOrder, setSortOrder] = useState<'ascend' | 'descend' | undefined>();
    const [currentPg, setCurrentPg] = useState<number>(0)
    const history = useHistory()
    const query = useQuery()


    useEffect(() => {
        const loadPage = query?.get('page')
        let page = 0
        if (!loadPage) page = 1
        else page = +loadPage

        history.push('?page=' + page)
        setCurrentPg(page - 1)
        run({
            page: page - 1,
            searcher: {},
            sorter: {},
        });
        getProductPlan()
    }, []);

    const onHandleChangePage = (e: number) => {
        setCurrentPg(e)
        const _e = e + 1
        history.push('?page=' + _e)
    }

    return (
        <div style={{ padding: '24px' }}>
            <h2>Partner Transaction</h2>
            <div className="filter-container_transaction">
                <div>
                    <span>Total Records:</span> <span>{listTx?.data?.pagination?.totalRows ?? 0}</span>
                </div>
            </div>
            <TableTx
                productPlan={listProductPlans?.data}
                onPageChange={run}
                dataSource={listTx?.data?.hits ?? []}
                isLoading={loading}
                onCurrentPage={(e) => onHandleChangePage(e)}
                currentPage={0}
                sortOrder={sortOrder}
                pagination={listTx?.data.pagination}
                onFilter={({ page, sorter, pageSize }) => {
                    setSortOrder(!!sorter.title ? (sorter.title === 'asc' ? 'ascend' : 'descend') : undefined);
                    run({
                        page,
                        sorter,
                        searcher: {
                            user: { value: searchValue, operator: 'contains' },
                        },
                        pageSize,
                    });
                }} onRemove={() => message.error("To Delete! You must be owner!")} />
        </div>
    );
};
