import { ROUTERS_MAIN } from '@constants';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '@services/api';
import { RoleEnum, UserLoginParams } from '@types';
import { errorHandle } from '@utils/exception';

export const fetchProfile = createAsyncThunk('user/fetchProfile', async () => {
  try {
    return true;
  } catch (error) {
    return undefined;
  }
});

export const userLogin = createAsyncThunk('user/login', async (params: UserLoginParams, thunkApi) => {
  try {
    const userData = await api.login({ username: params.username, password: params.password });
    api.setToken(userData.data.payload.adminToken);
    api.setAuthenticated(userData.data.admin);

    const role = userData.data.admin.role;
    if (role === RoleEnum.ROOT) {
      window.location.href = ROUTERS_MAIN.DASHBOARD;
    } else if (role == RoleEnum.PARTNER_ADMIN) {
      window.location.href = ROUTERS_MAIN.USER;
    } else {
      window.location.href = ROUTERS_MAIN.APP;
    }
    return userData.data.admin;
  } catch (error: any) {
    errorHandle(error);
    return undefined;
  }
});

export const userProfile = createAsyncThunk('user/profile', async () => {
  try {
    const userData = await api.getProfile();

    return userData.data;
  } catch (error: any) {
    errorHandle(error);
    return undefined;
  }
});
