import api from '@services/api';
import { ParamsSettings, ResponseDetailSuccess } from '@types';
import { useRequest } from 'ahooks';
import {
  ConfigEnvParams,
  PaypalConfigDocument,
  PaypalConfigParams,
  PaypalconfigEnvParams,
} from 'src/types/admin-system';

export const getConfigEnv = async (): Promise<ResponseDetailSuccess<string>> => {
  const response: ResponseDetailSuccess<string> = await api.getListDocument({
    url: '/admin/root/config/env',
    params: {},
  });
  return response;
};

export const useGetConfigEnv = () => {
  return useRequest<ResponseDetailSuccess<string>, []>(getConfigEnv, { manual: true });
};

export const getPaypalConfigByEnv = async (
  data: PaypalconfigEnvParams,
): Promise<ResponseDetailSuccess<PaypalConfigDocument>> => {
  const response: ResponseDetailSuccess<PaypalConfigDocument> = await api.getListDocument({
    url: `/admin/payments/gateway/${data.gateway}/config/${data.env}`,
    params: {},
  });
  return response;
};

export const useGetPaypalConfigByEnv = () => {
  return useRequest<ResponseDetailSuccess<PaypalConfigDocument>, [PaypalconfigEnvParams]>(getPaypalConfigByEnv, {
    manual: true,
  });
};

export const setPaypalConfigEnv = async (
  data: PaypalConfigParams,
): Promise<ResponseDetailSuccess<PaypalConfigDocument>> => {
  const response: ResponseDetailSuccess<PaypalConfigDocument> = await api.putDocument({
    url: `/admin/payments/gateway/config`,
    data: data,
  });

  return response.data as unknown as ResponseDetailSuccess<PaypalConfigDocument>;
};

export const setConfigEnv = async (data: ConfigEnvParams): Promise<ResponseDetailSuccess<string>> => {
  const response: ResponseDetailSuccess<string> = await api.postDocument({
    url: `/admin/root/config/env`,
    data: data,
  });

  return response.data as unknown as ResponseDetailSuccess<string>;
};
