import { CopyOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import api from '@services/api';
import { RoleEnum } from '@types';
import { useDebounce, useUpdateEffect } from 'ahooks';
import { Button, Input } from 'antd';
import { FC, useState } from 'react';

type Props = {
  onSearch: (e: string) => void;
  onOpen: () => void;
  onCopy?: () => void;
  totalRecords: number;
  isShowListKeyword?: boolean;
};
export const FilterKeyword: FC<Props> = ({ totalRecords, onSearch, onOpen, onCopy, isShowListKeyword }) => {
  const [searchKey, setSearchKey] = useState<string>('');
  const debouncedValue = useDebounce(searchKey, { wait: 500 });
  const userData = api.getAuthenticated();

  useUpdateEffect(() => {
    onSearch?.(debouncedValue);
  }, [debouncedValue]);

  return (
    <>
      <div className="button-add_copy">
        {userData.role != RoleEnum.ROOT && (
          <Button style={{ marginRight: 20 }} type="primary" onClick={onOpen}>
            <PlusCircleOutlined />Add
          </Button>
        )}
        {userData.role != RoleEnum.ROOT && (
          <Button type="primary" onClick={onCopy}>
            <CopyOutlined />Copy
          </Button>
        )}
      </div>
      <div className="filter-container">
        <div className="search_partname">
          <Input
            placeholder={isShowListKeyword ? 'Enter search keyword' : 'Enter search app id'}
            value={searchKey}
            onChange={(e) => setSearchKey(e.target.value)}
            suffix={<SearchOutlined />}
            variant="outlined"
          />
        </div>
        <div className="total-recore">
          <span>Total Records:</span> <span>{totalRecords}</span>
        </div>
      </div>
    </>
  );
};
