import { AntTable } from '@components';
import { ParamsSettings, SubscriptionEnum } from '@types';
import { getPaginationFromAntdTable, getSorterParamsFromAntdTable } from '@utils/helper';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AddKeywordParams, AdminPartnerKeywordDocument } from 'src/types/admin-keyword';
import { ProductPlanDocument } from 'src/types/admin-subscription';
import { Typography } from 'antd';
import { subscriptionMap } from '@utils/subscriptionMap';

interface Props {
  dataSource?: any[];
  isLoading?: boolean;
  pagination?: {
    totalRows?: number;
  };
  sortOrder?: 'ascend' | 'descend';
  currentPage: number;
  productPlan: ProductPlanDocument[] | undefined;
  onFilter?: (params: ParamsSettings<any>) => void;
  onRemove: (e: AddKeywordParams, currentPage: number) => void;
  onRefresh?: () => void;
  onPageChange: (e: any) => void;
  onCurrentPage: (e: number) => void;
}

const { Text } = Typography;

export const TableTx = ({ dataSource = [], isLoading, pagination, currentPage, productPlan, onFilter, onCurrentPage, }: Props) => {
  const navigate = useHistory();
  const [open, setOpen] = useState<boolean>(false);
  const [rowSelected, setRowSelected] = useState<AdminPartnerKeywordDocument>();
  const [pageSize, setPageSize] = useState<number>(10)



  const [openDelete, setOpenDelete] = useState(false);

  const handleClickOpen = (row: AdminPartnerKeywordDocument) => () => {
    setRowSelected(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenDelete(false);
  };

  const removeKeyDetails = (key: string) => {
    const keys = ['_id', 'createdAt', 'updatedAt', '__v', 'title', 'company', 'status'];
    return keys.includes(key);
  };

  const columns: ColumnsType<any> = [
    {
      key: 'user',
      dataIndex: 'user',
      title: 'Partner',
      render: (value: string, item) => <Text onClick={handleClickOpen(item)}>{value}</Text>,
      width: 200,
    },
    {
      key: 'orderId',
      dataIndex: 'orderId',
      title: 'Order ID',
      render: (value: string, item) => <Text onClick={handleClickOpen(item)}>{value}</Text>,
      width: 200,
    },

    {
      key: 'amount',
      dataIndex: 'amount',
      title: 'Amount',
      render: (value: string) => {
        return <Text>{value ?? 0}</Text>
      },
      width: 200,
    },

    {
      key: 'paymentGateWay',
      dataIndex: 'paymentGateWay',
      title: 'Payment Gateway',
      render: (value: string, item: AdminPartnerKeywordDocument) => {
        return <Text>{value ?? ''}</Text>
      },
      width: 200,
    },

    {
      key: 'subscriptionType',
      dataIndex: 'subscriptionType',
      title: 'Subscription Type',
      render: (value: string, item) => {
        const subscriptionType = item?.subscriptionType ?? SubscriptionEnum.EXPIRED;
        const color = subscriptionMap?.[subscriptionType as SubscriptionEnum]?.color ?? subscriptionMap[SubscriptionEnum.EXPIRED].color;
        const bg = subscriptionMap?.[subscriptionType as SubscriptionEnum]?.bg ?? subscriptionMap[SubscriptionEnum.EXPIRED].bg;
        const title = productPlan?.find(item => item.subscriptionType == subscriptionType)?.name ?? subscriptionType
        return (
          <Text
            style={{
              color,
              background: bg,
              borderWidth: 1,
              borderColor: color,
              borderRadius: 20,
              width: 'fit-content',
              padding: '2px 16px',
              borderStyle: 'solid',
            }}
          >
            {title}
          </Text>
        );
      },
      width: 200,
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: 'Created At',
      render: (value: string, item) => <Text onClick={handleClickOpen(item)}>{moment(value).format('YYYY-MM-DD HH:mm:ss')}</Text>,
      sorter: (a, b) => a.createdAt.length - b.createdAt.length,
      width: 200,
    },

  ];

  return (
    <div style={{ margin: '24px 0' }}>
      <AntTable
        columns={columns}
        dataSource={dataSource}
        rowKey={(r) => r._id}
        loading={isLoading}
        pagination={{
          pageSize: pageSize,
          total: pagination?.totalRows,
          current: currentPage + 1,
          pageSizeOptions: [10]
        }}
        onChange={(pagination, _, sorter, extra) => {
          onCurrentPage(getPaginationFromAntdTable({ pagination, extra }));
          onFilter?.({
            page: getPaginationFromAntdTable({ pagination, extra }),
            sorter: getSorterParamsFromAntdTable({ sorter }),
            searcher: {},
            pageSize: pagination.pageSize
          });
          setPageSize(pagination.pageSize ?? pageSize)
        }}
      />
    </div>
  );
};
